import Api from "../../../services/api";

/**
 * @copyright 2019 @ DigiNet
 * @author HOANGNAM
 * @create 06/15/2020
 * @Example
 */

export const types = {
    W05F0001_LOAD_CBO_CONTRACT_TYPE: 'W05F0001_LOAD_CBO_CONTRACT_TYPE',
    W05F0001_LOAD_CBO_CONTRACT_TYPE_SUCCESS: 'W05F0001_LOAD_CBO_CONTRACT_TYPE_SUCCESS',
    W05F0001_LOAD_CBO_EMPLOYEE: 'W05F0001_LOAD_CBO_EMPLOYEE',
    W05F0001_LOAD_CBO_EMPLOYEE_SUCCESS: 'W05F0001_LOAD_CBO_EMPLOYEE_SUCCESS',
    W05F0001_LOAD_AMOUNT_OF_CONTRACT_BY_STATUS: 'W05F0001_LOAD_AMOUNT_OF_CONTRACT_BY_STATUS',
    W05F0001_LOAD_AMOUNT_OF_CONTRACT_BY_STATUS_SUCCESS: 'W05F0001_LOAD_AMOUNT_OF_CONTRACT_BY_STATUS_SUCCESS',
    W05F0001_LOAD_LIST_CONTRACT: 'W05F0001_LOAD_LIST_CONTRACT',
    W05F0001_LOAD_LIST_CONTRACT_SUCCESS: 'W05F0001_LOAD_LIST_CONTRACT_SUCCESS',
    W05F0001_DELETE_ITEM_CONTRACT: "W05F0001_DELETE_ITEM_CONTRACT",
    W05F0001_DELETE_ITEM_CONTRACT_SUCCESS: "W05F0001_DELETE_ITEM_CONTRACT_SUCCESS",
    W05F0001_LOAD_CBO_LIST_OBJECT: "W05F0001_LOAD_CBO_LIST_OBJECT",
    W05F0001_LOAD_CBO_LIST_OBJECT_SUCCESS: "W05F0001_LOAD_CBO_LIST_OBJECT_SUCCESS",
    W05F1001_GET_CBO_TRANS_TYPE: "W05F1001_GET_CBO_TRANS_TYPE",
    W05F1001_GET_CBO_TRANS_TYPE_SUCCESS: "W05F1001_GET_CBO_TRANS_TYPE_SUCCESS",
    W05F1001_GET_DATA_CANCEL_INFORMATION: "W05F1001_GET_DATA_CANCEL_INFORMATION",
    W05F1001_GET_CBO_PROJECT: "W05F1001_GET_CBO_PROJECT",
    W05F1001_GET_CBO_PROJECT_SUCCESS: "W05F1001_GET_CBO_PROJECT_SUCCESS",
    W05F1001_SAVE_CANCEL: "W05F1001_SAVE_CANCEL",
    W05F0001_SAVE_HISTORY: "W05F0001_SAVE_HISTORY"
}

export function addHistory(params, cb) {
    return async () => {
        await Api.put('/history/add', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    cb && cb(400);
                }
                else {
                    cb && cb(null)
                }
            })
    }
}

export function getCboContractTypes(params, cb) {
    return {
        type: types.W05F0001_LOAD_CBO_CONTRACT_TYPE,
        params,
        cb
    }

}

export function getCboEmployees(params, cb) {
    return {
        type: types.W05F0001_LOAD_CBO_EMPLOYEE,
        params,
        cb
    }
}

export function getAmountOfContractByStatus(params, cb) {
    return {
        type: types.W05F0001_LOAD_AMOUNT_OF_CONTRACT_BY_STATUS,
        params,
        cb
    }
}

export function getListContracts(params, cb) {
    return {
        type: types.W05F0001_LOAD_LIST_CONTRACT,
        params,
        cb
    }
}

export function deleteItemContract(params, cb) {
    return {
        type: types.W05F0001_DELETE_ITEM_CONTRACT,
        params,
        cb
    }
}

export function getCboListObjects(params, cb) {
    return {
        type: types.W05F0001_LOAD_CBO_LIST_OBJECT,
        params,
        cb
    }
}

export function getCboTransType(cb) {
    return {
        type: types.W05F1001_GET_CBO_TRANS_TYPE,
        cb
    }
}

export function getDataCancelInformation(params, cb) {
    return {
        type: types.W05F1001_GET_DATA_CANCEL_INFORMATION,
        params,
        cb
    }
}

export function getCboProject(params, cb) {
    return {
        type: types.W05F1001_GET_CBO_PROJECT,
        params,
        cb
    }
}

export function saveCancel(params, cb) {
    return {
        type: types.W05F1001_SAVE_CANCEL,
        params,
        cb
    }
}

export function saveHistory(params, cb) {
    return {
        type: types.W05F0001_SAVE_HISTORY,
        params,
        cb
    }
}