/**
 * @copyright 2020 @ DigiNet
 * @author ANHHUY
 * @create 07/08/2020
 * @Example
 */

import Api from '../../../services/api';
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "../../W0X/W05F0004/W05F0004_actions";
import { delay } from "redux-saga";

export default function W05F0004Sagas() {
    return [
        watchGetListDeployments(),
        watchGetListObjects(),
        watchGetCboStatus(),
        watchGetFumigationEdit(),
        watchSaveFumigation(),
        watchGetCboFumigationMethod(),
        watchSaveChangeStatus(),
        watchDeleteItemDeloyment(),
        watchLoadReceipt(),
        watchSaveReceipt(),
        watchCompleteReceipt(),
        watchGetPayment(),
        watchGetPaymentDefault(),
        watchGetComboPaymentMethod(),
        watchSavePayments(),
        watchGetFlexCaption(),
        watchSendRequestDelete()
    ];
}

//get cbo status ...
export function* getCboStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0005/get-combo-status', data.params);
        if (response && response.data) {
            yield put({
                type: types.W05F0004_LOAD_CBO_STATUS_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo status error');
    }
}

export function* watchGetCboStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0004_LOAD_CBO_STATUS, getCboStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get list deployment ...
export function* getListDeployments(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/get-list-deployment', data.params);
        if (response && response.data) {
            yield put({
                type: types.W05F0004_LOAD_LIST_DEPLOYMENTS_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get list Deployments error')
    }
}

export function* watchGetListDeployments() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0004_LOAD_LIST_DEPLOYMENTS, getListDeployments);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

//get list OBJECTS ...
export function* getListObjects(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w91f0001/load-objects', data.params);
        if (response && response.data) {
            yield put({
                type: types.W05F0004_LOAD_LIST_OBJECTS_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get list OBJECTS error')
    }
}

export function* watchGetListObjects() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0004_LOAD_LIST_OBJECTS, getListObjects);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

//get list Fumigation Edit ...
export function* getFumigationEdit(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0005/get-fumigation-edit', data.params);
        if (response && response.data) {
            yield put({
                type: types.W05F0004_LOAD_FUMIGATION_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get fumigation edit error')
    }
}

export function* watchGetFumigationEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0004_LOAD_FUMIGATION, getFumigationEdit);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

//get list Fumigation Edit ...
export function* saveFumigation(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/save-fumigation', data.params);
        if (response && response.data) {
            yield put({
                type: types.W05F0004_SAVE_FUMIGATION_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('save fumigation error')
    }
}

export function* watchSaveFumigation() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0004_SAVE_FUMIGATION, saveFumigation);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

//get cbo FUMIGATION_METHOD ...
export function* getCboFumigationMethod(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0005/get-combo-fumigation-method', data.params);
        if (response && response.data) {
            yield put({
                type: types.W05F0004_LOAD_CBO_FUMIGATION_METHOD_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get Cbo fumigation method error')
    }
}

export function* watchGetCboFumigationMethod() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0004_LOAD_CBO_FUMIGATION_METHOD, getCboFumigationMethod);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

//save status changes ...
export function* saveChangeStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/save-change-status', data.params);
        if (response && response.data) {
            yield put({
                type: types.W05F0004_SAVE_CHANGE_STATUS_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('save cbo Status changes error')
    }
}

export function* watchSaveChangeStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0004_SAVE_CHANGE_STATUS, saveChangeStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

// delete item deloyment
export function* deleteItemDeloyment(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/delete-deployment', data.params);
        if (response && response.data) {
            yield put({
                type: types.W05F0004_DELETE_DEPLOYMENT_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('delete item deloyment error');
    }
}

export function* watchDeleteItemDeloyment() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0004_DELETE_DEPLOYMENT, deleteItemDeloyment);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

// LOAD RECEIPT
export function* loadReceipt(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/get-receipts', data.params);
        if (response && response.data) {
            yield put({
                type: types.W05F0004_LOAD_RECEIPT_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('LOAD RECEIPT error');
    }
}

export function* watchLoadReceipt() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0004_LOAD_RECEIPT, loadReceipt);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
// SAVE RECEIPT
export function* saveReceipt(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/save-receipts', data.params);
        if (response && response.data) {
            yield put({
                type: types.W05F0004_SAVE_RECEIPT_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('SAVE RECEIPT error');
    }
}

export function* watchSaveReceipt() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0004_SAVE_RECEIPT, saveReceipt);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
// complete RECEIPT
export function* completeReceipt(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/update-complete', data.params);
        if (response && response.data) {
            yield put({
                type: types.W05F0004_COMPLETE_RECEIPT_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('SAVE RECEIPT error');
    }
}

export function* watchCompleteReceipt() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0004_COMPLETE_RECEIPT, completeReceipt);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
// load payment
export function* getPayment(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/load-popup-payment', data.params);
        if (response && response.data) {
            yield put({
                type: types.W05F0004_LOAD_PAYMENT_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('LOAD PAYMENT error');
    }
}

export function* watchGetPayment() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0004_LOAD_PAYMENT, getPayment);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
// load payment DEFAULT
export function* getPaymentDefault(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/get-payment-default', data.params);
        if (response && response.data) {
            yield put({
                type: types.W05F0004_LOAD_PAYMENT_DEFAULT_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('LOAD PAYMENT default error');
    }
}

export function* watchGetPaymentDefault() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0004_LOAD_PAYMENT_DEFAULT, getPaymentDefault);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
// get payment method
export function* getComboPaymentMethod(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0000/get-combo-payment-method', data.params);
        if (response && response.data) {
            yield put({
                type: types.W05F0004_GET_COMBO_PAYMENT_METHOD_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log(' get payment method error');
    }
}

export function* watchGetComboPaymentMethod() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0004_GET_COMBO_PAYMENT_METHOD, getComboPaymentMethod);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

// save Payments
export function* savePayments(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/save-payments', data.params);
        if (response && response.data) {
            yield put({
                type: types.W05F0004_SAVE_PAYMENT_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log(' save Payments error');
    }
}

export function* watchSavePayments() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0004_SAVE_PAYMENT, savePayments);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get flex caption
export function* getFlexCaption(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0007/get-flex-caption', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0004_FLEX_CAPTION_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get flex caption error');
    }
}

export function* watchGetFlexCaption() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0004_FLEX_CAPTION, getFlexCaption);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Send Request Delete
export function* sendRequestDelete(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/request-delete-mapping', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('send request delete error');
    }
}

export function* watchSendRequestDelete() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0004_SEND_REQUEST_DELETE, sendRequestDelete);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}