/**
 * @copyright 2020 @ DigiNet
 * @author ANHHUY
 * @create 08/10/2020
 * @Example
 */

export const types = {
    W05F0006_GET_SALE_CONTRACTS: 'W05F0006_GET_SALE_CONTRACTS',
    W05F0006_GET_SALE_CONTRACTS_SUCCESS : 'W05F0006_GET_SALE_CONTRACTS_SUCCESS',
    W05F0006_GET_MAPPING_BY_SALE_CONTRACT: 'W05F0006_GET_MAPPING_BY_SALE_CONTRACT',
    W05F0006_GET_MAPPING_BY_SALE_CONTRACT_SUCCESS : 'W05F0006_GET_MAPPING_BY_SALE_CONTRACT_SUCCESS',
    W05F0006_GET_COMBO_INVENTORY: 'W05F0006_GET_COMBO_INVENTORY',
    W05F0006_GET_COMBO_INVENTORY_SUCCESS : 'W05F0006_GET_COMBO_INVENTORY_SUCCESS',
    W05F0006_GET_COMBO_OLD_MAPPING : 'W05F0006_GET_COMBO_OLD_MAPPING',
}

export function getSaleContracts(params, cb) {
    return {
        type: types.W05F0006_GET_SALE_CONTRACTS,
        params,
        cb
    }
}

export function getMappingBySaleContract(params, cb) {
    return {
        type: types.W05F0006_GET_MAPPING_BY_SALE_CONTRACT,
        params,
        cb
    }
}

export function getCboInventory(params, cb) {
    return {
        type: types.W05F0006_GET_COMBO_INVENTORY,
        params,
        cb
    }
}

export function getCboOldMapping(params, cb) {
    return {
        type: types.W05F0006_GET_COMBO_OLD_MAPPING,
        params,
        cb
    }
}