/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 06/15/2020
 * @Example
 */

import Api from '../../../services/api';
export const types = {
    W05F0002_GET_CBO_CONTRACT_TYPE: "W05F0002_GET_CBO_CONTRACT_TYPE",
    W05F0002_GET_CBO_CONTRACT_TYPE_SUCCESS: "W05F0002_GET_CBO_CONTRACT_TYPE_SUCCESS",
    W05F0002_GET_CBO_BANKS: "W05F0002_GET_CBO_BANKS",
    W05F0002_GET_CBO_BANKS_SUCCESS: "W05F0002_GET_CBO_BANKS_SUCCESS",
    W05F0002_GET_CBO_PAYMENT_METHOD: "W05F0002_GET_CBO_PAYMENT_METHOD",
    W05F0002_GET_CBO_PAYMENT_METHOD_SUCCESS: "W05F0002_GET_CBO_PAYMENT_METHOD_SUCCESS",
    W05F0002_GET_CBO_PORTS: "W05F0002_GET_CBO_PORTS",
    W05F0002_GET_CBO_PORTS_SUCCESS: "W05F0002_GET_CBO_PORTS_SUCCESS",
    W05F0002_GET_CBO_DELIVERY_METHOD: "W05F0002_GET_CBO_DELIVERY_METHOD",
    W05F0002_GET_CBO_DELIVERY_METHOD_SUCCESS: "W05F0002_GET_CBO_DELIVERY_METHOD_SUCCESS",
    W05F0002_GET_CBO_CURRENCY: "W05F0002_GET_CBO_CURRENCY",
    W05F0002_GET_CBO_CURRENCY_SUCCESS: "W05F0002_GET_CBO_CURRENCY_SUCCESS",
    W05F0002_GET_CBO_EMPLOYEES: "W05F0002_GET_CBO_EMPLOYEES",
    W05F0002_GET_CBO_EMPLOYEES_SUCCESS: "W05F0002_GET_CBO_EMPLOYEES_SUCCESS",
    W05F0002_GET_CBO_STATUS: "W05F0002_GET_CBO_STATUS",
    W05F0002_GET_CBO_STATUS_SUCCESS: "W05F0002_GET_CBO_STATUS_SUCCESS",
    W05F0002_GET_INVENTORIES: "W05F0002_GET_INVENTORIES",
    W05F0002_GET_INVENTORIES_SUCCESS: "W05F0002_GET_INVENTORIES_SUCCESS",
    W05F0002_GET_CBO_PACKING_METHOD: "W05F0002_GET_CBO_PACKING_METHOD",
    W05F0002_GET_CBO_PACKING_METHOD_SUCCESS: "W05F0002_GET_CBO_PACKING_METHOD_SUCCESS",
    W05F0002_GET_CBO_PAYMENT_TERM: "W05F0002_GET_CBO_PAYMENT_TERM",
    W05F0002_GET_CBO_PAYMENT_TERM_SUCCESS: "W05F0002_GET_CBO_PAYMENT_TERM_SUCCESS",
    W05F0002_GET_BASE_CURRENCY: "W05F0002_GET_BASE_CURRENCY",
    W05F0002_GET_BASE_CURRENCY_SUCCESS: "W05F0002_GET_BASE_CURRENCY_SUCCESS",
    W05F0002_GET_DECIMAL_QUANTITY: "W05F0002_GET_DECIMAL_QUANTITYS",
    W05F0002_GET_DECIMAL_QUANTITY_SUCCESS: "W05F0002_GET_DECIMAL_QUANTITY_SUCCESS",
    W05F0002_GET_DETAIL_SUPPLEMENT: "W05F0002_GET_DETAIL_SUPPLEMENT",
    W05F0002_GET_DETAIL_SUPPLEMENT_SUCCESS: "W05F0002_GET_DETAIL_SUPPLEMENT_SUCCESS",
    W05F0002_GET_MASTER_SUPPLEMENT: "W05F0002_GET_MASTER_SUPPLEMENT",
    W05F0002_GET_MASTER_SUPPLEMENT_SUCCESS: "W05F0002_GET_MASTER_SUPPLEMENT_SUCCESS",
    W05F0002_GET_CBO_MARKING: "W05F0002_GET_CBO_MARKING",
    W05F0002_GET_CBO_MARKING_SUCCESS: "W05F0002_GET_CBO_MARKING_SUCCESS",
    W05F0002_GET_CBO_PACKING_TYPE: "W05F0002_GET_CBO_PACKING_TYPE",
    W05F0002_GET_CBO_PACKING_TYPE_SUCCESS: "W05F0002_GET_CBO_PACKING_TYPE_SUCCESS",
    W05F0002_GET_CBO_UNIT: "W05F0002_GET_CBO_UNIT",
    W05F0002_GET_CONTRACT_NO: "W05F0002_GET_CONTRACT_NO",
    W05F0002_GET_CONTRACT_NO_SUCCESS: "W05F0002_GET_CONTRACT_NO_SUCCESS",
    W05F0002_LOAD_FORM: "W05F0002_LOAD_FORM",
    W05F0002_LOAD_FORM_SUCCESS: "W05F0002_LOAD_FORM_SUCCESS",
    W05F0002_GET_CBO_OBJECT_CUSTOMER: "W05F0002_GET_CBO_OBJECT_CUSTOMER",
    W05F0002_GET_CBO_OBJECT_CUSTOMER_SUCCESS: "W05F0002_GET_CBO_OBJECT_CUSTOMER_SUCCESS",
    W05F0002_SAVE_DATA_HISTORY: "W05F0002_SAVE_DATA_HISTORY",
    W05F0002_SAVE_DATA_HISTORY_SUCCESS: "W05F0002_SAVE_DATA_HISTORY_SUCCESS",
    W05F0002_SAVE_ADD_NEW: "W05F0002_SAVE_ADD_NEW",
    W05F0002_SAVE_ADD_NEW_SUCCESS: "W05F0002_SAVE_ADD_NEW_SUCCESS",
    W05F0002_SAVE_EDIT: "W05F0002_SAVE_EDIT",
    W05F0002_SAVE_EDIT_SUCCESS: "W05F0002_SAVE_EDIT_SUCCESS",
    W05F0002_GET_DATA_FORM_VIEW: "W05F0002_GET_DATA_FORM_VIEW",
    W05F0002_GET_DATA_FORM_VIEW_SUCCESS: "W05F0002_GET_DATA_FORM_VIEW_SUCCESS",
    W05F0002_SAVE_CBO_UNIT: "W05F0002_SAVE_CBO_UNIT",
    W05F0002_SAVE_CBO_UNIT_SUCCESS: "W05F0002_SAVE_CBO_UNIT_SUCCESS",
    W05F0002_GET_ISQUOTATION: "W05F0002_GET_ISQUOTATION",
    W05F0002_GET_ISQUOTATION_FINISH: "W05F0002_GET_ISQUOTATION_FINISH",
    W05F0002_GET_EXCEEDCONTRACT: "W05F0002_GET_EXCEEDCONTRACT",
    W05F0002_GET_CBO_DEPOSIT_STATUS: "W05F0002_GET_CBO_DEPOSIT_STATUS",
    W05F0002_GET_CBO_DEPOSIT_STATUS_SUCCESS: "W05F0002_GET_CBO_DEPOSIT_STATUS_SUCCESS",
    W05F0002_GET_CBO_BANK_ACCOUNT: "W05F0002_GET_CBO_BANK_ACCOUNT",
    W05F0002_GET_CBO_BANK_ACCOUNT_SUCCESS: "W05F0002_GET_CBO_BANK_ACCOUNT_SUCCESS",
};

export function loadCboContractType(cb) {
    return {
        type: types.W05F0002_GET_CBO_CONTRACT_TYPE,
        cb
    }
}

export function loadCboBanks(params, cb) {
    return {
        type: types.W05F0002_GET_CBO_BANKS,
        params,
        cb
    }
}

export function loadCboPaymentMethod(params, cb) {
    return {
        type: types.W05F0002_GET_CBO_PAYMENT_METHOD,
        params,
        cb
    }
}

export function loadCboPorts(params, cb) {
    return {
        type: types.W05F0002_GET_CBO_PORTS,
        params,
        cb
    }
}

export function loadCboDeliveryMethod(params, cb) {
    return {
        type: types.W05F0002_GET_CBO_DELIVERY_METHOD,
        params,
        cb
    }
}

export function loadCboCurrency(params, cb) {
    return {
        type: types.W05F0002_GET_CBO_CURRENCY,
        params,
        cb
    }
}

export function loadCboEmployees(params, cb) {
    return {
        type: types.W05F0002_GET_CBO_EMPLOYEES,
        params,
        cb
    }
}

export function loadCboPackingMethod(params, cb) {
    return {
        type: types.W05F0002_GET_CBO_PACKING_METHOD,
        params,
        cb
    }
}

export function loadCboPaymentTerm(params, cb) {
    return {
        type: types.W05F0002_GET_CBO_PAYMENT_TERM,
        params,
        cb
    }
}

export function loadCboBaseCurrency(params, cb) {
    return {
        type: types.W05F0002_GET_BASE_CURRENCY,
        params,
        cb
    }
}

export function loadCboStatus(params, cb) {
    return {
        type: types.W05F0002_GET_CBO_STATUS,
        params,
        cb
    }
}

export function getInventories(params, cb) {
    return {
        type: types.W05F0002_GET_INVENTORIES,
        params,
        cb
    }
}

export function getDeicimalQuantity(params, cb) {
    return {
        type: types.W05F0002_GET_DECIMAL_QUANTITY,
        params,
        cb
    }
}

export function getDetailSupplement(params, cb) {
    return {
        type: types.W05F0002_GET_DETAIL_SUPPLEMENT,
        params,
        cb
    }
}

export function getMasterSupplement(params, cb) {
    return {
        type: types.W05F0002_GET_MASTER_SUPPLEMENT,
        params,
        cb
    }
}

export function loadCboMarking(params, cb) {
    return {
        type: types.W05F0002_GET_CBO_MARKING,
        params,
        cb
    }
}

export function loadCboPackingType(params, cb) {
    return {
        type: types.W05F0002_GET_CBO_PACKING_TYPE,
        params,
        cb
    }
}

export function loadCboUnit(params, cb) {
    return async () => {
        await Api.put('/w05f0000/get-combo-unit', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    cb && cb(result, null);
                } else if (result.data) {
                    cb && cb(null, result.data);
                    return result.data;
                } else {
                    cb && cb(null, null);
                }
            });
    }
}

export function getContractNo(params, cb) {
    return {
        type: types.W05F0002_GET_CONTRACT_NO,
        params,
        cb
    }
}

export function loadForm(params, cb) {
    return {
        type: types.W05F0002_LOAD_FORM,
        params,
        cb
    }
}

export function loadCboObject(params, cb) {
    return {
        type: types.W05F0002_GET_CBO_OBJECT_CUSTOMER,
        params,
        cb
    }
}

export function saveHistoryData(params, cb) {
    return {
        type: types.W05F0002_SAVE_DATA_HISTORY,
        params,
        cb
    }
}

export function saveEdit(params, cb) {
    return {
        type: types.W05F0002_SAVE_EDIT,
        params,
        cb
    }
}

export function saveAddNew(params, cb) {
    return {
        type: types.W05F0002_SAVE_ADD_NEW,
        params,
        cb
    }
}

export function loadBaseCurrency(params, cb) {
    return {
        type: types.W05F0002_GET_BASE_CURRENCY,
        params,
        cb
    }
}

export function loadFormView(params, cb) {
    return {
        type: types.W05F0002_GET_DATA_FORM_VIEW,
        params,
        cb
    }
}

export function onSaveDataUnit(dataCboUnit) {
    return {
        type: types.W05F0002_SAVE_CBO_UNIT,
        dataCboUnit
    }
}

export function getIsQuotation(params, cb) {
    return {
        type: types.W05F0002_GET_ISQUOTATION,
        params,
        cb
    }
}

export function getIsQuotationFinish(params, cb) {
    return {
        type: types.W05F0002_GET_ISQUOTATION_FINISH,
        params,
        cb
    }
}

export function getExceedContract(params, cb) {
    return {
        type: types.W05F0002_GET_EXCEEDCONTRACT,
        params,
        cb
    }
}

export function getCboDepositStatus(params, cb) {
    return {
        type: types.W05F0002_GET_CBO_DEPOSIT_STATUS,
        params,
        cb
    }
}

export function getCboBankAccount(params, cb) {
    return {
        type: types.W05F0002_GET_CBO_BANK_ACCOUNT,
        params,
        cb
    }
}