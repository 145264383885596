/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 07/07/2020
 * @Example
 */
import { types } from "./W05F0003_actions";

const initialState = {
    totalSaleContract: 0,
    contractIDBefore: '',
    getBaseCurrency: {},
    getDecimalQuantity: {},
    getCboStatus: [],
    dataMasterSupplement: [],
    dataDetailSupplement: [],
    getDataSaleContract: [],
    getCboPaymentMethod: [],
    getCboFumigationMethod: [],
    getFlexCaption: [],
    getFlexCaptionName: [],
    paramBySaleBefore: [],
    // getCboFumiCompany: [],
    paramsMasterSup: {},
    dataCboEmployees: []
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W05F0003_GET_DECIMAL_QUANTITY_SUCCESS:
            return { ...state, getDecimalQuantity: action.data };
        case types.W05F0003_GET_MASTER_SUPPLEMENT_SUCCESS:
            const dataMasterSupplement = action.data.filter(item => item.DefaultUse === 1); // Just get DefaultUse = 1
            return { ...state, dataMasterSupplement, paramsMasterSup: action.params };
        case types.W05F0003_GET_BASE_CURRENCY_SUCCESS:
            return { ...state, getBaseCurrency: action.data };
        case types.W05F0003_LOAD_CBO_FUMIGATION_METHOD_SUCCESS:
            return { ...state, getCboFumigationMethod: action.data };
        case types.W05F0003_LOAD_CBO_STATUS_SUCCESS:
            return { ...state, getCboStatus: action.data };
        case types.W05F0003_GET_CBO_PAYMENT_METHOD_SUCCESS:
            return { ...state, getCboPaymentMethod: action.data };
        // case types.W05F0003_LOAD_CBO_FUMIGATION_COMPANY_SUCCESS:
        //     if (action.params.skip === 0) return { ...state };
        //     const { rows } = action.data;
        //     const getCboFumiCompany = rows.map((item) => {
        //         return {
        //             FumigationCompany: item.ObjectID,
        //             FumigationCompanyName: item.ObjectNameU,
        //         };
        //     })
        //     return { ...state, getCboFumiCompany };
        case types.W05F0003_FLEX_CAPTION_SUCCESS:
            const enableFlexCaption = action.data.filter(item => item.Disabled === 0);
            const getFlexCaptionName = action.data.length > 0
                ? enableFlexCaption.map(item => item.FieldName)
                : action.data;
            return { ...state, getFlexCaption: enableFlexCaption, getFlexCaptionName };
        case types.W05F0003_GET_SALE_CONTRACTS_SUCCESS:
            const contractIDBefore = action.param.ContractID;
            return { ...state, dataSaleContracts: action.data, contractIDBefore };
        case types.W05F0003_GET_MAPPING_BY_SALE_CONTRACTS_SUCCESS:
            return { ...state, dataBySaleContracts: action.data, paramBySaleBefore: action.param };
        case types.W05F0003_GET_CBO_EMPLOYEES_SUCCESS:
            return { ...state, dataCboEmployees: action.data };
        case types.W05F0003_GET_DETAIL_SUPPLEMENT_SUCCESS:
            return { ...state, dataDetailSupplement: action.data };
        default:
            return state;
    }
}
