/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 06/15/2020
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import Api from '../../../services/api';
import { types } from "./W05F0002_actions";

export default function W05F0002Sagas() {
    return [
        watchGetCboContractType(),
        watchGetCboBanks(),
        watchGetCboPaymentMenthod(),
        watchGetCboPorts(),
        watchGetCboDeliveryMethod(),
        watchGetCboCurrency(),
        watchGetCboEmployees(),
        watchGetCboStatus(),
        watchGetInventories(),
        watchGetCboPackingMethod(),
        watchGetCboPaymentTerm(),
        watchGetCboBaseCurrency(),
        watchGetDecimalQuantity(),
        watchGetDetailSupplement(),
        watchGetMasterSupplement(),
        watchGetCboMarking(),
        watchGetCboPackingType(),
        watchGetContractNo(),
        watchLoadForm(),
        watchGetCboObject(),
        watchSaveHistoryData(),
        watchSaveEdit(),
        watchSaveAddNew(),
        watchGetDataFormView(),
        watchOnSaveDataUnit(),
        watchGetIsQuotation(),
        watchGetIsQuotationFinish(),
        watchGetExceedContract(),
        watchGetCboDepositStatus(),
        watchGetCboBankAccount()
    ];
}

//get cbo contract type
export function* getCboContractType(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0000/get-combo-contractType');
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_CBO_CONTRACT_TYPE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo contract type error');
    }

}

export function* watchGetCboContractType() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_CBO_CONTRACT_TYPE, getCboContractType);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo banks
export function* getCboBanks(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0000/get-combo-bank');
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_CBO_BANKS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo banks error');
    }

}

export function* watchGetCboBanks() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_CBO_BANKS, getCboBanks);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo payment menthod
export function* getCboPaymentMenthod(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0000/get-combo-payment-method');
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_CBO_PAYMENT_METHOD_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo payment method error');
    }

}

export function* watchGetCboPaymentMenthod() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_CBO_PAYMENT_METHOD, getCboPaymentMenthod);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo ports
export function* getCboPorts(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0000/get-combo-port');
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_CBO_PORTS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo ports error');
    }

}

export function* watchGetCboPorts() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_CBO_PORTS, getCboPorts);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo delivery method
export function* getCboDeliveryMethod(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0000/get-combo-delivery-method');
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_CBO_DELIVERY_METHOD_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo delivery method error');
    }

}

export function* watchGetCboDeliveryMethod() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_CBO_DELIVERY_METHOD, getCboDeliveryMethod);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo currency
export function* getCboCurrency(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0000/get-combo-currency');
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_CBO_CURRENCY_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo currency error');
    }
}

export function* watchGetCboCurrency() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_CBO_CURRENCY, getCboCurrency);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo employees
export function* getCboEmployees(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-combo-employee', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_CBO_EMPLOYEES_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo employees error');
    }
}

export function* watchGetCboEmployees() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_CBO_EMPLOYEES, getCboEmployees);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo status
export function* getCboStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-combo-status', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_CBO_STATUS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo status error');
    }
}

export function* watchGetCboStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_CBO_STATUS, getCboStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get inventories
export function* getInventories(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-inventories', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_INVENTORIES_SUCCESS, data: response.data, skip: data.params.skip });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo inventories error');
    }
}

export function* watchGetInventories() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_INVENTORIES, getInventories);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo packing method
export function* getCboPackingMethod(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0000/get-combo-packing-method');
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_CBO_PACKING_METHOD_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo packing method error');
    }
}

export function* watchGetCboPackingMethod() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_CBO_PACKING_METHOD, getCboPackingMethod);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo payment term
export function* getCboPaymentTerm(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0000/get-combo-payment-term');
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_CBO_PAYMENT_TERM_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo payment term error');
    }
}

export function* watchGetCboPaymentTerm() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_CBO_PAYMENT_TERM, getCboPaymentTerm);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo base currency
export function* getCboBaseCurrency(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0000/get-base-currency');
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_BASE_CURRENCY_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo base currency error');
    }
}

export function* watchGetCboBaseCurrency() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_BASE_CURRENCY, getCboBaseCurrency);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get decimal quantity
export function* getDecimalQuantity(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-decimal-quantity', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_DECIMAL_QUANTITY_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get decimal quantity error');
    }
}

export function* watchGetDecimalQuantity() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_DECIMAL_QUANTITY, getDecimalQuantity);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get detail supplement
export function* getDetailSupplement(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-detail-supplement', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_DETAIL_SUPPLEMENT_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get detail supplement error');
    }
}

export function* watchGetDetailSupplement() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_DETAIL_SUPPLEMENT, getDetailSupplement);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get master supplement
export function* getMasterSupplement(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-master-supplement', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_MASTER_SUPPLEMENT_SUCCESS, data: response.data, params: data.params });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get master supplement error');
    }
}

export function* watchGetMasterSupplement() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_MASTER_SUPPLEMENT, getMasterSupplement);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo marking
export function* getCboMarking(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-combo-marking', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_CBO_MARKING_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo marking error');
    }
}

export function* watchGetCboMarking() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_CBO_MARKING, getCboMarking);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo packing type
export function* getCboPackingType(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-combo-packing-type', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_CBO_PACKING_TYPE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo packing type error');
    }
}

export function* watchGetCboPackingType() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_CBO_PACKING_TYPE, getCboPackingType);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get contract no
export function* getContractNo(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-contract-no', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_CONTRACT_NO_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get contract no error');
    }
}

export function* watchGetContractNo() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_CONTRACT_NO, getContractNo);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//load form
export function* loadForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/load-form', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0002_LOAD_FORM_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get load form error');
    }
}

export function* watchLoadForm() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_LOAD_FORM, loadForm);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get object customer
export function* getCboObject(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w91f0001/load-objects', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_CBO_OBJECT_CUSTOMER_SUCCESS, data: response.data && response.data.rows });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get grid object customer error');
    }
}

export function* watchGetCboObject() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_CBO_OBJECT_CUSTOMER, getCboObject);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Save history
export function* saveHistoryData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/history/add', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0002_SAVE_DATA_HISTORY_SUCCESS, data: response.data && response.data.rows });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save error');
    }
}

export function* watchSaveHistoryData() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_SAVE_DATA_HISTORY, saveHistoryData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Save Edit
export function* saveEdit(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/edit', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save edit error');
    }
}

export function* watchSaveEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_SAVE_EDIT, saveEdit);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Save Add New
export function* saveAddNew(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/insert', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save add new error');
    }
}

export function* watchSaveAddNew() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_SAVE_ADD_NEW, saveAddNew);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Save Edit
export function* getDataFormView(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0003/load-form-view', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_DATA_FORM_VIEW_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load form view error');
    }
}

export function* watchGetDataFormView() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_DATA_FORM_VIEW, getDataFormView);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Save combo UnitData local only
export function* onSaveDataUnit(data) {
    yield put({ type: types.W05F0002_SAVE_CBO_UNIT_SUCCESS, data: data.dataCboUnit });
}

export function* watchOnSaveDataUnit() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_SAVE_CBO_UNIT, onSaveDataUnit);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}



// Get IsQuotation
export function* getIsQuotation(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-is-quotation', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get isQuotation error');
    }
}

export function* watchGetIsQuotation() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_ISQUOTATION, getIsQuotation);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

// Get IsQuotation Finish
export function* getIsQuotationFinish(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-quotation-finish', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get isQuotation finish error');
    }
}

export function* watchGetIsQuotationFinish() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_ISQUOTATION_FINISH, getIsQuotationFinish);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

// Get getExceedContract
export function* getExceedContract(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0002/get-default', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get getExceedContract error');
    }
}

export function* watchGetExceedContract() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_EXCEEDCONTRACT, getExceedContract);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


export function* getCboDepositStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0002/get-deposit-status', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0002_GET_CBO_DEPOSIT_STATUS_SUCCESS, data: response.data });
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('getCboDepositStatus error');
    }
}

export function* watchGetCboDepositStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_CBO_DEPOSIT_STATUS, getCboDepositStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


export function* getCboBankAccount(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0002/get-bank-account', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            yield put({ type: types.W05F0002_GET_CBO_BANK_ACCOUNT_SUCCESS, data: response.data });
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('getCboBankAccount error');
    }
}

export function* watchGetCboBankAccount() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0002_GET_CBO_BANK_ACCOUNT, getCboBankAccount);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
