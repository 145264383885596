/**
 * @copyright 2020 @ DigiNet
 * @author NHATLONG
 * @create 09/07/2020
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, takeEvery, take, takeLatest, put } from "redux-saga/effects";
import Api from '../../../services/api';
import { types } from "./W05F0005_actions";

export default function W05F0005Sagas() {
    return [
        watchGetCbStatus(),
        watchGetCbBank(),
        watchGetListInventories(),
        watchGetVoucherNum(),
        watchGetDetailSupplement(),
        watchGetPackingMethod(),
        watchGetPackingType(),
        watchGetCbMarking(),
        watchGetCbGeneral(),
        watchGetDecimals(),
        watchGetCbVessel(),
        watchGetCbPort(),
        watchOnInsert(),
        watchGetFormEdit(),
        watchOnUpdate(),
        watchGetCbFumigationMethod(),
        watchGetGridFumigation(),
        watchGetHistory(),
        watchSaveHistory(),
        watchGetCboPaymentMenthod(),
        watchGetMasterSupplement(),
        watchGetFlexCaption(),
        watchGetReceipt(),
        watchGetObjects(),
        watchSaveRequestLot(),
        watchGetDeliveryQuantity(),
        watchSaveConfirmDeliveryQuantity(),
        watchSendRequestDeleteMapping(),
        watchGetCboCurator(),
        watchGetAdjustColumns(),
        watchGetFieldsCompare(),
        watchGetCbLocationNo(),
        watchSaveLocationNo(),
    ];
}

export function* getCbStatus(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0005/get-combo-status", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetCbStatus() {
    while (true) {
        const watcher = yield takeEvery(
            types.W05F0005_GET_STATUS,
            getCbStatus
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCbBank(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/w05f0000/get-combo-bank", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetCbBank() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0005_GET_BANK,
            getCbBank
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getListInventories(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0005/get-inventories", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetListInventories() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0005_GET_LIST_INVENTORIES,
            getListInventories
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getVoucherNum(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0005/get-voucher-num", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetVoucherNum() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0005_GET_VOUCHER_NUM,
            getVoucherNum
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* getDetailSupplement(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0005/get-detail-supplement", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetDetailSupplement() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0005_GET_DETAIL_SUPPLEMENT,
            getDetailSupplement
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* getPackingMethod(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/w05f0000/get-combo-packing-method", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetPackingMethod() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0005_GET_PACKING_METHOD,
            getPackingMethod
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* getPackingType(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0000/get-combo-packing-type", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetPackingType() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0005_GET_PACKING_TYPE,
            getPackingType
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* getCbMarking(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0000/get-combo-marking", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetCbMarking() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0005_GET_MARKING,
            getCbMarking
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCbGeneral(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0005/get-combo-fumigation-company", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetCbGeneral() {
    while (true) {
        const watcher = yield takeEvery(
            types.W05F0005_GET_CB_GENERAL,
            getCbGeneral
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* getDecimals(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0005/get-decimals", data.params);
        if (res && res.data) {
            yield put({ type: types.W05F0005_GET_DECIMALS_SUCCESS, data: res.data });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetDecimals() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0005_GET_DECIMALS,
            getDecimals
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* getCbVessel(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0005/get-combo-vessel", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetCbVessel() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0005_GET_VESSEL,
            getCbVessel
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* getCbPort(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/w05f0000/get-combo-port", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetCbPort() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0005_GET_PORT,
            getCbPort
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* onInsert(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0005/insert", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchOnInsert() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0005_ON_INSERT,
            onInsert
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* getFormEdit(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0005/load-form-edit", data.params);
        if (res && res.data) {
            yield put({ type: types.W05F0005_GET_FORM_EDIT_SUCCESS, data: res.data });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetFormEdit() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0005_GET_FORM_EDIT,
            getFormEdit
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* onUpdate(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0005/edit", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchOnUpdate() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0005_ON_UPDATE,
            onUpdate
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* getCbFumigationMethod(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0005/get-combo-fumigation-method", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetCbFumigationMethod() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0005_GET_CB_FUMIGATION_METHOD,
            getCbFumigationMethod
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* getGridFumigation(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0005/get-fumigation-edit", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetGridFumigation() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0005_GET_GRID_FUMIGATION,
            getGridFumigation
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* saveHistory(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/history/add", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchSaveHistory() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0005_SAVE_HISTORY,
            saveHistory
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* getHistory(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/history/search", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetHistory() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0005_GET_HISTORY,
            getHistory
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//get cbo payment menthod
export function* getCboPaymentMenthod(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0000/get-combo-payment-method');
        if (response && response.data) {
            yield put({ type: types.W05F0005_GET_CBO_PAYMENT_METHOD_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo payment method error');
    }

}

export function* watchGetCboPaymentMenthod() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0005_GET_CBO_PAYMENT_METHOD, getCboPaymentMenthod);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get master supplement
export function* getMasterSupplement(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-master-supplement', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0005_GET_MASTER_SUPPLEMENT_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get master supplement error');
    }
}

export function* watchGetMasterSupplement() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0005_GET_MASTER_SUPPLEMENT, getMasterSupplement);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get flex caption
export function* getFlexCaption(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0007/get-flex-caption', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0005_FLEX_CAPTION_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get flex caption error');
    }
}

export function* watchGetFlexCaption() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0005_FLEX_CAPTION, getFlexCaption);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Get Receipt
export function* getReceiptLot(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/get-receipts', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0005_GET_RECEIPT_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get receipt error')
    }
}

export function* watchGetReceipt() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0005_GET_RECEIPT, getReceiptLot);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getObjects(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w91f0001/load-objects", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetObjects() {
    while (true) {
        const watcher = yield takeEvery(
            types.W05F0005_GET_CB_OBJECTS,
            getObjects
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//Save request LOT
export function* saveRequestLot(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/save-req-split-lot', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('Save request LOT error')
    }
}

export function* watchSaveRequestLot() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0005_SAVE_REQUEST_LOT, saveRequestLot);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Load data delivery quantity
export function* getDeliveryQuantity(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0005/load-popup-delivery-quantity', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('load delivery quantity error')
    }
}

export function* watchGetDeliveryQuantity() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0005_GET_DELIVERY_QUANTITY, getDeliveryQuantity);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Save confirm delivery quantity
export function* saveConfirmDeliveryQuantity(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0005/save-confirm-delivery-quantity', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('Save confirm delivery quantity error')
    }
}

export function* watchSaveConfirmDeliveryQuantity() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0005_SAVE_CONFIRM_DELIVERY_QUANTITY, saveConfirmDeliveryQuantity);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


//Send Request Delete Mapping
export function* sendRequestDeleteMapping(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0005/request-delete-mapping-lot', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('Send request delete mapping error')
    }
}

export function* watchSendRequestDeleteMapping() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0005_SEND_REQUEST_DELETE_MAPPING, sendRequestDeleteMapping);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboCurator(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w91f0001/load-objects', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('Send request delete mapping error')
    }
}

export function* watchGetCboCurator() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0005_GET_CB_CURATOR, getCboCurator);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getAdjustColumns(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0005/load-adjust-columns', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0005_GET_ADJUST_COLUMNS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('W05F0005 get adjust columns error')
    }
}

export function* watchGetAdjustColumns() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0005_GET_ADJUST_COLUMNS, getAdjustColumns);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getFieldsCompare(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0005/load-fields-compare', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0005_GET_FIELD_COMPARE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('W05F0005 get field compare error')
    }
}

export function* watchGetFieldsCompare() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0005_GET_FIELD_COMPARE, getFieldsCompare);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCbLocationNo(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0005/get-list-location-no', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('W05F0005 getCbLocationNo error')
    }
}

export function* watchGetCbLocationNo() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0005_GET_CB_LOCATION_NO, getCbLocationNo);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* saveLocationNo(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0005/save', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('W05F0005 saveLocationNo error')
    }
}

export function* watchSaveLocationNo() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0005_SAVE_LOCATION_NO, saveLocationNo);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}