import { types } from './W05F0004_actions';

const initialState = {
    getListDeployments: [],
    getCboStatus: [],
    getFumigationEdit: [],
    getCboFumigationMethod: [],
    getCboPaymentMenthod: [],
    getFlexCaption: [],
    getFlexCaptionName: []
};

export default function (state = initialState, action = {}) {

    switch (action.type) {
        case types.W05F0004_LOAD_CBO_STATUS_SUCCESS:
            return {
                ...state,
                getCboStatus: action.data
            };
        case types.W05F0004_LOAD_LIST_DEPLOYMENTS_SUCCESS:
            return {
                ...state,
                getListDeployments: action.data
            };
        case types.W05F0004_LOAD_FUMIGATION_SUCCESS:
            return {
                ...state,
                getFumigationEdit: action.data
            };
        case types.W05F0004_LOAD_CBO_FUMIGATION_METHOD_SUCCESS:
            return {
                ...state,
                getCboFumigationMethod: action.data
            };
        case types.W05F0004_GET_COMBO_PAYMENT_METHOD_SUCCESS:
            return { ...state, getCboPaymentMenthod: action.data };
        case types.W05F0004_FLEX_CAPTION_SUCCESS:
            const enableFlexCaption = action.data.filter(item => item.Disabled === 0);
            const getFlexCaptionName = action.data.length > 0
                ? enableFlexCaption.map(item => item.FieldName)
                : action.data;
            return { ...state, getFlexCaption: enableFlexCaption, getFlexCaptionName };
        default:
            return state;
    }
}