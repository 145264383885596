/**
 * @copyright 2020 @ DigiNet
 * @author NHATLONG
 * @create 06/08/2020
 * @Example
 */
import { types } from "./W05F0005_actions";
import moment from 'moment';
import _ from 'lodash';
const initialState = {
    oldDataInventories: [],
    getCboPaymentMenthod: [],
    getMasterSupplement: [],
    getFlexCaption: [],
    getFlexCaptionName: [],
    getDecimal: [],
    dataAddReceipt: [],
    dataAdjustColumns: [],
    dataFieldsCompare: {}
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W05F0005_GET_FORM_EDIT_SUCCESS: {
            const { detail, master, attachments } = action.data;
            if (!detail && !master) return;
            let oldDataInventories = [];
            //Format Time Master
            Object.keys(master).forEach(dataMaster => {
                if (moment(master[dataMaster], moment.ISO_8601, true).isValid() && !_.isNumber(master[dataMaster])) {
                    master[dataMaster] = moment(master[dataMaster]).format('YYYY-MM-DD')
                }
            });
            //Add "Old" in every key name Because Grid In Devextreme will set all field when edit And Format Time
            oldDataInventories = detail.map(dataInven => {
                const appendOld = "Old";
                let obj = {};
                Object.keys(dataInven).map(key => {
                    const newKey = key + appendOld;
                    if (moment(dataInven[key], moment.ISO_8601, true).isValid() && !_.isNumber(dataInven[key])) {
                        dataInven[key] = moment(dataInven[key]).format('YYYY-MM-DD')
                    }
                    obj[newKey] = dataInven[key];
                    return { [key]: newKey };
                });
                return obj;
            });
            return { ...state, oldDataMaster: master, oldDataAttachments: attachments, oldDataInventories };
        }
        case types.W05F0005_GET_CBO_PAYMENT_METHOD_SUCCESS:
            return { ...state, getCboPaymentMenthod: action.data };
        case types.W05F0005_GET_MASTER_SUPPLEMENT_SUCCESS:
            return { ...state, getMasterSupplement: action.data };
        case types.W05F0005_FLEX_CAPTION_SUCCESS:
            const enableFlexCaption = action.data.filter(item => item.Disabled === 0);
            const getFlexCaptionName = action.data.length > 0
                ? enableFlexCaption.map(item => item.FieldName)
                : action.data;
            return { ...state, getFlexCaption: enableFlexCaption, getFlexCaptionName };
        case types.W05F0005_GET_DECIMALS_SUCCESS:
            return { ...state, getDecimal: action.data };
        case types.W05F0005_GET_RECEIPT_SUCCESS:
            return { ...state, dataAddReceipt: action.data };
        case types.W05F0005_GET_ADJUST_COLUMNS_SUCCESS:
            return { ...state, dataAdjustColumns: action.data };
        case types.W05F0005_GET_FIELD_COMPARE_SUCCESS:
            return { ...state, dataFieldsCompare: action.data };
        default:
            return state;
    }
}
