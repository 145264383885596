/**
 * @copyright 2020 @ DigiNet
 * @author ANHHUY
 * @create 07/08/2020
 * @Example
 */

import Api from "../../../services/api";

export const types = {
    W05F0004_LOAD_CBO_STATUS: 'W05F0004_LOAD_CBO_STATUS',
    W05F0004_LOAD_CBO_STATUS_SUCCESS: 'W05F0004_LOAD_CBO_STATUS_SUCCESS',
    W05F0004_LOAD_LIST_DEPLOYMENTS: 'W05F0004_LOAD_LIST_DEPLOYMENTS',
    W05F0004_LOAD_LIST_DEPLOYMENTS_SUCCESS: 'W05F0004_LOAD_LIST_DEPLOYMENTS_SUCCESS',
    W05F0004_LOAD_LIST_OBJECTS: 'W05F0004_LOAD_LIST_OBJECTS',
    W05F0004_LOAD_LIST_OBJECTS_SUCCESS: 'W05F0004_LOAD_LIST_OBJECTS_SUCCESS',
    W05F0004_LOAD_FUMIGATION: 'W05F0004_LOAD_FUMIGATION',
    W05F0004_LOAD_FUMIGATION_SUCCESS: 'W05F0004_LOAD_FUMIGATION_SUCCESS',
    W05F0004_SAVE_FUMIGATION: 'W05F0004_SAVE_FUMIGATION',
    W05F0004_SAVE_FUMIGATION_SUCCESS: 'W05F0004_SAVE_FUMIGATION_SUCCESS',
    W05F0004_LOAD_CBO_FUMIGATION_METHOD: 'W05F0004_LOAD_CBO_FUMIGATION_METHOD',
    W05F0004_LOAD_CBO_FUMIGATION_METHOD_SUCCESS: 'W05F0004_LOAD_CBO_FUMIGATION_METHOD_SUCCESS',
    W05F0004_SAVE_CHANGE_STATUS: 'W05F0004_SAVE_CHANGE_STATUS',
    W05F0004_SAVE_CHANGE_STATUS_SUCCESS: 'W05F0004_SAVE_CHANGE_STATUS_SUCCESS',
    W05F0004_DELETE_DEPLOYMENT: 'W05F0004_DELETE_DEPLOYMENT',
    W05F0004_DELETE_DEPLOYMENT_SUCCESS: 'W05F0004_DELETE_DEPLOYMENT_SUCCESS',
    W05F0004_LOAD_RECEIPT: 'W05F0004_LOAD_RECEIPT',
    W05F0004_LOAD_RECEIPT_SUCCESS: 'W05F0004_LOAD_RECEIPT_SUCCESS',
    W05F0004_SAVE_RECEIPT: 'W05F0004_SAVE_RECEIPT',
    W05F0004_SAVE_RECEIPT_SUCCESS: 'W05F0004_SAVE_RECEIPT_SUCCESS',
    W05F0004_COMPLETE_RECEIPT: 'W05F0004_COMPLETE_RECEIPT',
    W05F0004_COMPLETE_RECEIPT_SUCCESS: 'W05F0004_COMPLETE_RECEIPT_SUCCESS',
    W05F0004_LOAD_PAYMENT: 'W05F0004_LOAD_PAYMENT',
    W05F0004_LOAD_PAYMENT_SUCCESS: 'W05F0004_LOAD_PAYMENT_SUCCESS',
    W05F0004_LOAD_PAYMENT_DEFAULT: 'W05F0004_LOAD_PAYMENT_DEFAULT',
    W05F0004_LOAD_PAYMENT_DEFAULT_SUCCESS: 'W05F0004_LOAD_PAYMENT_DEFAULT_SUCCESS',
    W05F0004_GET_COMBO_PAYMENT_METHOD: 'W05F0004_GET_COMBO_PAYMENT_METHOD',
    W05F0004_GET_COMBO_PAYMENT_METHOD_SUCCESS: 'W05F0004_GET_COMBO_PAYMENT_METHOD_SUCCESS',
    W05F0004_SAVE_PAYMENT: "W05F0004_SAVE_PAYMENT",
    W05F0004_SAVE_PAYMENT_SUCCESS: "W05F0004_SAVE_PAYMENT_SUCCESS",
    W05F0004_FLEX_CAPTION: "W05F0004_FLEX_CAPTION",
    W05F0004_FLEX_CAPTION_SUCCESS: "W05F0004_FLEX_CAPTION_SUCCESS",
    W05F0004_SEND_REQUEST_DELETE: "W05F0004_SEND_REQUEST_DELETE"
}

export function addHistory(params, cb) {
    return async () => {
        await Api.put('/history/add', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    cb && cb(400);
                }
                else {
                    cb && cb(null)
                }
            })
    }
}

export function deleteItemDeloyment(params, cb) {
    return {
        type: types.W05F0004_DELETE_DEPLOYMENT,
        params,
        cb
    }
}

export function getListDeployments(params, cb) {
    return {
        type: types.W05F0004_LOAD_LIST_DEPLOYMENTS,
        params,
        cb
    }
}

export function getListObjects(params, cb) {
    return {
        type: types.W05F0004_LOAD_LIST_OBJECTS,
        params,
        cb
    }
}

export function getCboStatus(params, cb) {
    return {
        type: types.W05F0004_LOAD_CBO_STATUS,
        params,
        cb
    }

}

export function getFumigationEdit(params, cb) {
    return {
        type: types.W05F0004_LOAD_FUMIGATION,
        params,
        cb
    }

}

export function saveFumigation(params, cb) {
    return {
        type: types.W05F0004_SAVE_FUMIGATION,
        params,
        cb
    }

}

export function getCboFumigationMethod(params, cb) {
    return {
        type: types.W05F0004_LOAD_CBO_FUMIGATION_METHOD,
        params,
        cb
    }

}

export function saveChangeStatus(params, cb) {
    return {
        type: types.W05F0004_SAVE_CHANGE_STATUS,
        params,
        cb
    }

}

export function loadReceipt(params, cb) {
    return {
        type: types.W05F0004_LOAD_RECEIPT,
        params,
        cb
    }

}

export function saveReceipt(params, cb) {
    return {
        type: types.W05F0004_SAVE_RECEIPT,
        params,
        cb
    }

}

export function completeReceipt(params, cb) {
    return {
        type: types.W05F0004_COMPLETE_RECEIPT,
        params,
        cb
    }

}

export function getPayment(params, cb) {
    return {
        type: types.W05F0004_LOAD_PAYMENT,
        params,
        cb
    }

}

export function getPaymentMethodDefault(params, cb) {
    return async () => {
        await Api.put('/w05f0004/get-payment-default', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    cb && cb(result, null);
                } else if (result.data) {
                    cb && cb(null, result.data);
                    return result.data;
                } else {
                    cb && cb(null, null);
                }
            });
    }
}

export function getComboPaymentMethod(params, cb) {
    return {
        type: types.W05F0004_GET_COMBO_PAYMENT_METHOD,
        params,
        cb
    }

}

export function saveDataPayments(params, cb) {
    return {
        type: types.W05F0004_SAVE_PAYMENT,
        params,
        cb
    }
}

export function getFlexCaption(params, cb) {
    return {
        type: types.W05F0004_FLEX_CAPTION,
        params,
        cb
    }
}

export function getDecimalPayment(params, cb) {
    return async () => {
        await Api.put('/w05f0000/get-decimal-quantity', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    cb && cb(result, null);
                } else if (result.data) {
                    cb && cb(null, result.data);
                    return result.data;
                } else {
                    cb && cb(null, null);
                }
            });
    }
}

export function sendRequestDelete(params, cb) {
    return {
        type: types.W05F0004_SEND_REQUEST_DELETE,
        params,
        cb
    }
}