/**
 * @copyright 2020 @ DigiNet
 * @author ANHHUY
 * @create 08/10/2020
 * @Example
 */

import { types } from './W05F0006_actions';

const initialState = {
    getSaleContracts : [],
};

export default function (state = initialState, action = {}) {

    switch(action.type) {
        case types.W05F0006_GET_SALE_CONTRACTS_SUCCESS:
            return {
                ...state,
                getSaleContracts: action.data
            };
        default:
            return state;
    }
}