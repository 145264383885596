
import Api from '../../../services/api';
import { cancel, take, takeLatest, takeEvery } from "redux-saga/effects";
import { types } from "./W05F0007_actions";
import { delay } from "redux-saga";

export default function W05F0007Sagas() {
    return [
        watchGetSaleContract(),
        watchGetPurchaseContract(),
        watchGetDecimals(),
        watchGetCation(),
        watchGetCbDelivery(),
        watchGetcbUnitPack(),
        watchGetcbWarehousePacking(),
        watchGetcbDeliveryStatus(),
        watchGetCbMarking(),
        watchOnInsert(),
        watchGetFormEdit(),
        watchOnUpdate(),
        watchGetHistory(),
        watchGetListObjects(),
        watchGetInventories(),
        watchOnDelete(),
        watchGetCbLocationNo()
    ];
}
export function* getSaleContract(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0007/load-popup-add-sale-contract', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo employee error')
    }
}

export function* watchGetSaleContract() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0007_GET_SALE_CONTRACT, getSaleContract);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
export function* getPurchaseContract(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0007/load-popup-add-purchase-contract', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo employee error')
    }
}

export function* watchGetPurchaseContract() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0007_GET_PURCHASE_CONTRACT, getPurchaseContract);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}
export function* getDecimals(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0007/get-decimal-quantity', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo employee error')
    }
}

export function* watchGetDecimals() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0007_GET_DECIMALS, getDecimals);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}
export function* getCation(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0007/get-flex-caption', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo employee error')
    }
}

export function* watchGetCation() {
    while (true) {
        const watcher = yield takeEvery(types.W05F0007_GET_CAPTION, getCation);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}
export function* getCbDelivery(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0007/get-combo-vessel', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo employee error')
    }
}

export function* watchGetCbDelivery() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0007_GET_CB_DELIVERY, getCbDelivery);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}
export function* getcbUnitPack(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w91f0001/load-objects', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo employee error')
    }
}

export function* watchGetcbUnitPack() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0007_GET_CB_UNITPACK, getcbUnitPack);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}
export function* getcbWarehousePacking(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0007/get-combo-warehouse-packing', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo employee error')
    }
}

export function* watchGetcbWarehousePacking() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0007_GET_CB_WAREHOUSE_PACKING, getcbWarehousePacking);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}
export function* getcbDeliveryStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0007/get-combo-vessel-status', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo employee error')
    }
}

export function* watchGetcbDeliveryStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0007_GET_CB_DELIVERY_STATUS, getcbDeliveryStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}
export function* getCbMarking(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-combo-marking', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo employee error')
    }
}

export function* watchGetCbMarking() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0007_GET_CB_MARKING, getCbMarking);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}
export function* onInsert(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0007/insert', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo employee error')
    }
}

export function* watchOnInsert() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0007_ON_INSERT, onInsert);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}
export function* getFormEdit(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0007/load-form-edit', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo employee error')
    }
}

export function* watchGetFormEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0007_GET_FORM_EDIT, getFormEdit);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}
export function* onUpdate(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0007/edit', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo employee error')
    }
}

export function* watchOnUpdate() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0007_ON_UPDATE, onUpdate);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}
export function* getHistory(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/history/search", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetHistory() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0007_GET_HISTORY,
            getHistory
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* getListObjects(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w91f0001/load-objects", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetListObjects() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0007_GET_LIST_OBJECTS,
            getListObjects
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* getInventories(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0000/get-inventories", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchGetInventories() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0007_GET_LIST_INVENTORIES,
            getInventories
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* onDelete(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w05f0007/delete", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) { }
}

export function* watchOnDelete() {
    while (true) {
        const watcher = yield takeLatest(
            types.W05F0007_ON_DELETE,
            onDelete
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCbLocationNo(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0005/get-list-location-no', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('W05F0005 getCbLocationNo error')
    }
}

export function* watchGetCbLocationNo() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0007_GET_CB_LOCATION_NO, getCbLocationNo);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}