/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 07/07/2020
 * @Example
 */

import Api from "../../../services/api";
export const types = {
    W05F0003_GET_DETAIL_SUPPLEMENT: "W05F0003_GET_DETAIL_SUPPLEMENT",
    W05F0003_GET_DETAIL_SUPPLEMENT_SUCCESS: "W05F0003_GET_DETAIL_SUPPLEMENT_SUCCESS",
    W05F0003_GET_MASTER_SUPPLEMENT: "W05F0003_GET_MASTER_SUPPLEMENT",
    W05F0003_GET_MASTER_SUPPLEMENT_SUCCESS: "W05F0003_GET_MASTER_SUPPLEMENT_SUCCESS",
    W05F0003_LOAD_FORM_VIEW: "W05F0003_LOAD_FORM_VIEW",
    W05F0003_LOAD_FORM_VIEW_SUCCESS: "W05F0003_LOAD_FORM_VIEW_SUCCESS",
    W05F0003_GET_DECIMAL_QUANTITY: "W05F0003_GET_DECIMAL_QUANTITY",
    W05F0003_GET_DECIMAL_QUANTITY_SUCCESS: "W05F0003_GET_DECIMAL_QUANTITY_SUCCESS",
    W05F0003_GET_BASE_CURRENCY: "W05F0003_GET_BASE_CURRENCY",
    W05F0003_GET_BASE_CURRENCY_SUCCESS: "W05F0003_GET_BASE_CURRENCY_SUCCESS",
    W05F0003_GET_CBO_EMPLOYEES: "W05F0003_GET_CBO_EMPLOYEES",
    W05F0003_GET_CBO_EMPLOYEES_SUCCESS: "W05F0003_GET_CBO_EMPLOYEES_SUCCESS",
    W05F0003_GET_LIST_DEPLOYMENTS: "W05F0003_GET_LIST_DEPLOYMENTS",
    W05F0003_LOAD_CBO_FUMIGATION_METHOD: 'W05F0003_LOAD_CBO_FUMIGATION_METHOD',
    W05F0003_LOAD_CBO_FUMIGATION_METHOD_SUCCESS: 'W05F0003_LOAD_CBO_FUMIGATION_METHOD_SUCCESS',
    W05F0003_LOAD_CBO_FUMIGATION_COMPANY: 'W05F0003_LOAD_CBO_FUMIGATION_COMPANY',
    W05F0003_LOAD_CBO_FUMIGATION_COMPANY_SUCCESS: 'W05F0003_LOAD_CBO_FUMIGATION_COMPANY_SUCCESS',
    W05F0003_LOAD_FUMIGATION: 'W05F0003_LOAD_FUMIGATION',
    W05F0003_LOAD_FUMIGATION_SUCCESS: 'W05F0003_LOAD_FUMIGATION_SUCCESS',
    W05F0003_SAVE_FUMIGATION: 'W05F0003_SAVE_FUMIGATION',
    W05F0003_SAVE_FUMIGATION_SUCCESS: 'W05F0003_SAVE_FUMIGATION_SUCCESS',
    W05F0003_LOAD_CBO_STATUS: 'W05F0003_LOAD_CBO_STATUS',
    W05F0003_LOAD_CBO_STATUS_SUCCESS: 'W05F0003_LOAD_CBO_STATUS_SUCCESS',
    W05F0003_SAVE_CHANGE_STATUS: 'W05F0003_SAVE_CHANGE_STATUS',
    W05F0003_SAVE_CHANGE_STATUS_SUCCESS: 'W05F0003_SAVE_CHANGE_STATUS_SUCCESS',
    W05F0003_GET_SALE_CONTRACTS: 'W05F0003_GET_SALE_CONTRACTS',
    W05F0003_GET_SALE_CONTRACTS_SUCCESS: 'W05F0003_GET_SALE_CONTRACTS_SUCCESS',
    W05F0003_GET_MAPPING_BY_SALE_CONTRACTS: 'W05F0003_GET_MAPPING_BY_SALE_CONTRACTS',
    W05F0003_GET_MAPPING_BY_SALE_CONTRACTS_SUCCESS: 'W05F0003_GET_MAPPING_BY_SALE_CONTRACTS_SUCCESS',
    W05F0003_GET_RECEIPT: 'W05F0003_GET_RECEIPT',
    W05F0003_GET_RECEIPT_SUCCESS: 'W05F0003_GET_RECEIPT_SUCCESS',
    W05F0003_SAVE_RECEIPT: 'W05F0003_SAVE_RECEIPT',
    W05F0003_SAVE_RECEIPT_SUCCESS: 'W05F0003_SAVE_RECEIPT_SUCCESS',
    W05F0003_SAVE_COMPLETE_RECEIPT: 'W05F0003_SAVE_COMPLETE_RECEIPT',
    W05F0003_SAVE_COMPLETE_RECEIPT_SUCCESS: 'W05F0003_SAVE_COMPLETE_RECEIPT_SUCCESS',
    W05F0003_GET_POPUP_PAYMENT: 'W05F0003_GET_POPUP_PAYMENT',
    W05F0003_GET_POPUP_PAYMENT_SUCCESS: 'W05F0003_GET_POPUP_PAYMENT_SUCCESS',
    W05F0003_GET_PAYMENT_METHOD_DEFAULT: 'W05F0003_GET_PAYMENT_METHOD_DEFAULT',
    W05F0003_GET_PAYMENT_METHOD_DEFAULT_SUCCESS: 'W05F0003_GET_PAYMENT_METHOD_DEFAULT_SUCCESS',
    W05F0003_SAVE_PAYMENT: 'W05F0003_SAVE_PAYMENT',
    W05F0003_SAVE_PAYMENT_SUCCESS: 'W05F0003_SAVE_PAYMENT_SUCCESS',
    W05F0003_GET_CBO_PAYMENT_METHOD: "W05F0003_GET_CBO_PAYMENT_METHOD",
    W05F0003_GET_CBO_PAYMENT_METHOD_SUCCESS: "W05F0003_GET_CBO_PAYMENT_METHOD_SUCCESS",
    W05F0003_FLEX_CAPTION: "W05F0003_FLEX_CAPTION",
    W05F0003_FLEX_CAPTION_SUCCESS: "W05F0003_FLEX_CAPTION_SUCCESS",
};

export function getDetailSupplement(params, cb) {
    return {
        type: types.W05F0003_GET_DETAIL_SUPPLEMENT,
        params,
        cb
    }
}

export function getMasterSupplement(params, cb) {
    return {
        type: types.W05F0003_GET_MASTER_SUPPLEMENT,
        params,
        cb
    }
}

export function loadFormView(params, cb) {
    return {
        type: types.W05F0003_LOAD_FORM_VIEW,
        params,
        cb
    }
}

export function saveDataContract(params, cb) {
    return {
        type: types.W05F0003_SAVE_DATA_CONTRACT,
        params,
        cb
    }
}

export function getDecimalQuanlity(params, cb) {
    return {
        type: types.W05F0003_GET_DECIMAL_QUANTITY,
        params,
        cb
    }
}

export function loadBaseCurrency(params, cb) {
    return {
        type: types.W05F0003_GET_BASE_CURRENCY,
        params,
        cb
    }
}

export function loadCboEmployees(params, cb) {
    return {
        type: types.W05F0003_GET_CBO_EMPLOYEES,
        params,
        cb
    }
}

export function getListDeployments(params, cb) {
    return {
        type: types.W05F0003_GET_LIST_DEPLOYMENTS,
        params,
        cb
    }
}

export function getFumigationEdit(params, cb) {
    return {
        type: types.W05F0003_LOAD_FUMIGATION,
        params,
        cb
    }
}

export function getCboFumigationMethod(params, cb) {
    return {
        type: types.W05F0003_LOAD_CBO_FUMIGATION_METHOD,
        params,
        cb
    }
}

export function getComboFumigationCompany(params, cb) {
    return {
        type: types.W05F0003_LOAD_CBO_FUMIGATION_COMPANY,
        params,
        cb
    }
}

export function saveFumigation(params, cb) {
    return {
        type: types.W05F0003_SAVE_FUMIGATION,
        params,
        cb
    }
}

export function addHistory(params, cb) {
    return async () => {
        await Api.put('/history/add', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    cb && cb(400);
                }
                else {
                    cb && cb(null)
                }
            })
    }
}

export function getCboStatus(params, cb) {
    return {
        type: types.W05F0003_LOAD_CBO_STATUS,
        params,
        cb
    }
}

export function saveChangeStatus(params, cb) {
    return {
        type: types.W05F0003_SAVE_CHANGE_STATUS,
        params,
        cb
    }
}

export function loadSaleContracts(params, cb) {
    return {
        type: types.W05F0003_GET_SALE_CONTRACTS,
        params,
        cb
    }
}

export function loadMappingBySaleContract(params, cb) {
    return {
        type: types.W05F0003_GET_MAPPING_BY_SALE_CONTRACTS,
        params,
        cb
    }
}

export function loadReceipt(params, cb) {
    return {
        type: types.W05F0003_GET_RECEIPT,
        params,
        cb
    }
}

export function saveDataReceipt(params, cb) {
    return {
        type: types.W05F0003_SAVE_RECEIPT,
        params,
        cb
    }
}

export function saveCompleteReceipt(params, cb) {
    return {
        type: types.W05F0003_SAVE_COMPLETE_RECEIPT,
        params,
        cb
    }
}

export function getPopupPayment(params, cb) {
    return {
        type: types.W05F0003_GET_POPUP_PAYMENT,
        params,
        cb
    }
}

export function saveDataPayments(params, cb) {
    return {
        type: types.W05F0003_SAVE_PAYMENT,
        params,
        cb
    }
}

export function loadCboPaymentMethod(cb) {
    return {
        type: types.W05F0003_GET_CBO_PAYMENT_METHOD,
        cb
    }
}


export function getPaymentMethodDefault(params, cb) {
    return async () => {
        await Api.put('/w05f0004/get-payment-default', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    cb && cb(result, null);
                } else if (result.data) {
                    cb && cb(null, result.data);
                    return result.data;
                } else {
                    cb && cb(null, null);
                }
            });
    }
}

export function getFlexCaption(params, cb) {
    return {
        type: types.W05F0003_FLEX_CAPTION,
        params,
        cb
    }
}

export function getDecimalPayment(params, cb) {
    return async () => {
        await Api.put('/w05f0000/get-decimal-quantity', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    cb && cb(result, null);
                } else if (result.data) {
                    cb && cb(null, result.data);
                    return result.data;
                } else {
                    cb && cb(null, null);
                }
            });
    }
}