/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 06/15/2020
 * @Example
 */
import _ from "lodash";
import moment from 'moment';
import Config from "../../../config/index";
import { types } from "./W05F0002_actions";

const initialState = {
    getCboContractType: [],
    getCboBanks: [],
    getCboPaymentMethod: [],
    getCboPorts: [],
    getCboDeliveryMethod: [],
    getCboCurrency: [],
    getCboEmployees: [],
    getCboStatus: [],
    getInventories: [],
    getCboPackingMethod: [],
    getCboPaymentTerm: [],
    getBaseCurrency: [],
    getDecimalQuantity: {},
    dataCboUnit: {},
    getDetailSupplement: [],
    getMasterSupplement: [],
    getCboMarking: [],
    getCboPackingType: [],
    getContractNo: [],
    getCboObject: [],
    dataLoadForm: [],
    oldDataInventories: [],
    paramsMasterSup: {},
    getCboDepositStatus: [],
    getCboBankAccount: []
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W05F0002_GET_CBO_CONTRACT_TYPE_SUCCESS:
            return { ...state, getCboContractType: action.data };
        case types.W05F0002_GET_CBO_BANKS_SUCCESS:
            return { ...state, getCboBanks: action.data };
        case types.W05F0002_GET_CBO_PAYMENT_METHOD_SUCCESS:
            return { ...state, getCboPaymentMethod: action.data };
        case types.W05F0002_GET_CBO_PORTS_SUCCESS:
            return { ...state, getCboPorts: action.data };
        case types.W05F0002_GET_CBO_DELIVERY_METHOD_SUCCESS:
            return { ...state, getCboDeliveryMethod: action.data };
        case types.W05F0002_GET_CBO_CURRENCY_SUCCESS:
            return { ...state, getCboCurrency: action.data };
        case types.W05F0002_GET_CBO_EMPLOYEES_SUCCESS:
            return { ...state, getCboEmployees: action.data };
        case types.W05F0002_GET_CBO_STATUS_SUCCESS:
            return { ...state, getCboStatus: action.data };
        case types.W05F0002_GET_CBO_PACKING_METHOD_SUCCESS:
            return { ...state, getCboPackingMethod: action.data };
        case types.W05F0002_GET_INVENTORIES_SUCCESS:
            if (action.skip !== 0) return { ...state };
            return { ...state, getInventories: action.data };
        case types.W05F0002_GET_CBO_PAYMENT_TERM_SUCCESS:
            return { ...state, getCboPaymentTerm: action.data };
        case types.W05F0002_GET_BASE_CURRENCY_SUCCESS:
            return { ...state, getBaseCurrency: action.data };
        case types.W05F0002_GET_DECIMAL_QUANTITY_SUCCESS:
            return { ...state, getDecimalQuantity: action.data };
        case types.W05F0002_GET_DETAIL_SUPPLEMENT_SUCCESS:
            return { ...state, getDetailSupplement: action.data };
        case types.W05F0002_GET_MASTER_SUPPLEMENT_SUCCESS:
            let activeMasterSupplement = [];
            if (action.data.length > 0) {
                activeMasterSupplement = action.data.filter(item => item.DefaultUse === 1);
            }
            return { ...state, getMasterSupplement: activeMasterSupplement, paramsMasterSup: action.params };
        case types.W05F0002_GET_CBO_MARKING_SUCCESS:
            return { ...state, getCboMarking: action.data };
        case types.W05F0002_GET_CBO_PACKING_TYPE_SUCCESS:
            return { ...state, getCboPackingType: action.data };
        case types.W05F0002_GET_CONTRACT_NO_SUCCESS:
            return { ...state, getContractNo: action.data };
        case types.W05F0002_GET_CBO_OBJECT_CUSTOMER_SUCCESS:
            return { ...state, getCboObject: action.data };
        case types.W05F0002_GET_DATA_FORM_VIEW_SUCCESS:
            if (!action.data.master && !action.data.inventories) return;
            const { master: masterDataView, inventories: inventoriesView } = action.data;
            //Format Time Master 
            Object.keys(masterDataView).forEach(dataMaster => {
                if (Config.isValidDateTime(masterDataView[dataMaster])) {
                    masterDataView[dataMaster] = moment(masterDataView[dataMaster]).format('DD/MM/YYYY')
                }
            });
            const inventoriesDataView = inventoriesView.map(dataInven => {
                Object.keys(dataInven).forEach(key => {
                    if (Config.isValidDateTime(dataInven[key])) {
                        dataInven[key] = moment(dataInven[key]).format('YYYY-MM-DD')
                    }
                });
                return dataInven;
            });
            return { ...state, loadFormView: masterDataView, loadFormInvenView: inventoriesDataView };

        case types.W05F0002_LOAD_FORM_SUCCESS:
            if (!action.data.inventories && !action.data.master) return;
            const { inventories, master, attachments } = action.data;
            //Format Time Master 
            Object.keys(master).forEach(dataMaster => {
                if (!_.isNumber(master[dataMaster]) && moment(master[dataMaster], moment.ISO_8601, true).isValid()) {
                    master[dataMaster] = moment(master[dataMaster]).format('YYYY-MM-DD')
                }
            });
            //Add "Old" in every key name Because Grid In Devextreme will set all field when edit And Format Time
            let oldDataInventories = inventories.map(dataInven => {
                // const appendOld = "Old";
                // let obj = {};
                Object.keys(dataInven).forEach(key => {
                    // const newKey = key + appendOld;
                    if (!_.isNumber(dataInven[key]) && moment(dataInven[key], moment.ISO_8601, true).isValid()) {
                        dataInven[key] = moment(dataInven[key]).format('YYYY-MM-DD')
                    }
                    // obj[newKey] = dataInven[key];
                    // return { [key]: newKey };
                });
                return dataInven;
            });
            return { ...state, oldDataMaster: master, oldDataAttachments: attachments, oldDataInventories };
        case types.W05F0002_SAVE_CBO_UNIT_SUCCESS:
            return { ...state, dataCboUnit: action.data };
        case types.W05F0002_GET_CBO_DEPOSIT_STATUS_SUCCESS:
            return { ...state, getCboDepositStatus: action.data };
        case types.W05F0002_GET_CBO_BANK_ACCOUNT_SUCCESS:
            return { ...state, getCboBankAccount: action.data };
        default:

            return state;
    }
}
