/**
 * @copyright 2019 @ DigiNet
 * @author HOANGNAM
 * @create 06/15/2020
 * @Example
 */

import Api from '../../../services/api';
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "../../W0X/W05F0001/W05F0001_actions";
import { delay } from "redux-saga";

export default function W05F0001Sagas() {
    return [
        watchGetCboContractTypes(),
        watchGetCboEmployees(),
        watchGetAmountOfContractByStatus(),
        watchGetListContracts(),
        watchDeleteItemContract(),
        watchGetCboListObjects(),
        watchGetCboTransType(),
        watchGetDataCancelInformation(),
        watchGetCboProject(),
        watchSaveCancel(),
        watchSaveHistory()
    ];
}

//get cbo contract type...
export function* getCboContractTypes(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0000/get-combo-contractType', data.params);
        if (response && response.data) {
            yield put({
                type: types.W05F0001_LOAD_CBO_CONTRACT_TYPE_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo contract type error');
    }
}

export function* watchGetCboContractTypes() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0001_LOAD_CBO_CONTRACT_TYPE, getCboContractTypes);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo employee ...
export function* getCboEmployees(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-combo-employee', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo employee error')
    }
}

export function* watchGetCboEmployees() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0001_LOAD_CBO_EMPLOYEE, getCboEmployees);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}


//get amount of contract by status ...
export function* getAmountOfContractByStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-amount-of-contract', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0001_LOAD_AMOUNT_OF_CONTRACT_BY_STATUS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get amount of contract by status error')
    }
}

export function* watchGetAmountOfContractByStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0001_LOAD_AMOUNT_OF_CONTRACT_BY_STATUS, getAmountOfContractByStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}



//get list contract ...
export function* getListContracts(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/load-list-contract', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get list contract error')
    }
}

export function* watchGetListContracts() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0001_LOAD_LIST_CONTRACT, getListContracts);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

// delete item contract
export function* deleteItemContract(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/delete-contract', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('delete item contract error');
    }
}

export function* watchDeleteItemContract() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0001_DELETE_ITEM_CONTRACT, deleteItemContract);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

// get cbo list object
export function* getCboListObjects(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w91f0001/load-objects', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo list object error');
    }
}

export function* watchGetCboListObjects() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0001_LOAD_CBO_LIST_OBJECT, getCboListObjects);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


// get cbo transType
export function* getCboTransType(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0000/get-combo-transType');
        if (response && response.data) {
            yield put({ type: types.W05F1001_GET_CBO_TRANS_TYPE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo transType error');
    }
}

export function* watchGetCboTransType() {
    while (true) {
        const watcher = yield takeLatest(types.W05F1001_GET_CBO_TRANS_TYPE, getCboTransType);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

// get data cancel information
export function* getDataCancelInformation(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/load-cancel-information', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get data cancel information error');
    }
}

export function* watchGetDataCancelInformation() {
    while (true) {
        const watcher = yield takeLatest(types.W05F1001_GET_DATA_CANCEL_INFORMATION, getDataCancelInformation);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

// get cbo project
export function* getCboProject(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-project', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F1001_GET_CBO_PROJECT_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo project');
    }
}

export function* watchGetCboProject() {
    while (true) {
        const watcher = yield takeLatest(types.W05F1001_GET_CBO_PROJECT, getCboProject);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

// save cancel
export function* saveCancel(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/save-cancel', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('save cancel');
    }
}

export function* watchSaveCancel() {
    while (true) {
        const watcher = yield takeLatest(types.W05F1001_SAVE_CANCEL, saveCancel);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Save history
export function* saveHistory(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/history/add', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save history error');
    }
}

export function* watchSaveHistory() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0001_SAVE_HISTORY, saveHistory);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}





