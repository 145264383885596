export const types = {
    W05F0007_GET_SALE_CONTRACT: "W05F0007_GET_SALE_CONTRACT",
    W05F0007_GET_PURCHASE_CONTRACT: "W05F0007_GET_PURCHASE_CONTRACT",
    W05F0007_GET_DECIMALS: "W05F0007_GET_DECIMALS",
    W05F0007_GET_CAPTION: "W05F0007_GET_CAPTION",
    W05F0007_GET_CB_DELIVERY: "W05F0007_GET_CB_DELIVERY",
    W05F0007_GET_CB_DELIVERY_STATUS: "W05F0007_GET_CB_DELIVERY_STATUS",
    W05F0007_GET_CB_UNITPACK: "W05F0007_GET_CB_UNITPACK",
    W05F0007_GET_CB_WAREHOUSE_PACKING: "W05F0007_GET_CB_WAREHOUSE_PACKING",
    W05F0007_GET_CB_MARKING: "W05F0007_GET_CB_MARKING",
    W05F0007_ON_INSERT: "W05F0007_ON_INSERT",
    W05F0007_GET_FORM_EDIT: "W05F0007_GET_FORM_EDIT",
    W05F0007_GET_FORM_EDIT_SUCCESS: "W05F0007_GET_FORM_EDIT_SUCCESS",
    W05F0007_ON_UPDATE: "W05F0007_ON_UPDATE",
    W05F0007_GET_HISTORY: "W05F0007_GET_HISTORY",
    W05F0007_GET_LIST_OBJECTS: "W05F0007_GET_LIST_OBJECTS",
    W05F0007_GET_LIST_INVENTORIES: "W05F0007_GET_LIST_INVENTORIES",
    W05F0007_ON_DELETE: "W05F0007_ON_DELETE",
    W05F0007_GET_CB_LOCATION_NO: "W05F0007_GET_CB_LOCATION_NO"
}

export function getSaleContract(params, cb) {
    return {
        type: types.W05F0007_GET_SALE_CONTRACT,
        params,
        cb
    }
}
export function getPurchaseContract(params, cb) {
    return {
        type: types.W05F0007_GET_PURCHASE_CONTRACT,
        params,
        cb
    }
}
export function getDecimals(params, cb) {
    return {
        type: types.W05F0007_GET_DECIMALS,
        params,
        cb
    }
}
export function getCation(params, cb) {
    return {
        type: types.W05F0007_GET_CAPTION,
        params,
        cb
    }
}
export function getCbDelivery(params, cb) {
    return {
        type: types.W05F0007_GET_CB_DELIVERY,
        params,
        cb
    }
}
export function getcbUnitPack(params, cb) {
    return {
        type: types.W05F0007_GET_CB_UNITPACK,
        params,
        cb
    }
}
export function getcbWarehousePacking(params, cb) {
    return {
        type: types.W05F0007_GET_CB_WAREHOUSE_PACKING,
        params,
        cb
    }
}
export function getcbDeliveryStatus(params, cb) {
    return {
        type: types.W05F0007_GET_CB_DELIVERY_STATUS,
        params,
        cb
    }
}
export function getCbMarking(params, cb) {
    return {
        type: types.W05F0007_GET_CB_MARKING,
        params,
        cb
    }
}
export function onInsert(params, cb) {
    return {
        type: types.W05F0007_ON_INSERT,
        params,
        cb
    }
}
export function getFormEdit(params, cb) {
    return {
        type: types.W05F0007_GET_FORM_EDIT,
        params,
        cb
    }
}
export function onUpdate(params, cb) {
    return {
        type: types.W05F0007_ON_UPDATE,
        params,
        cb
    }
}

export function getHistory(params, cb) {
    return {
        type: types.W05F0007_GET_HISTORY,
        params,
        cb
    }
}
export function getListObjects(params, cb) {
    return {
        type: types.W05F0007_GET_LIST_OBJECTS,
        params,
        cb
    }
}
export function getInventories(params, cb) {
    return {
        type: types.W05F0007_GET_LIST_INVENTORIES,
        params,
        cb
    }
}
export function onDelete(params, cb) {
    return {
        type: types.W05F0007_ON_DELETE,
        params,
        cb
    }
}

export function getCbLocationNo(params, cb) {
    return {
        type: types.W05F0007_GET_CB_LOCATION_NO,
        params,
        cb
    }
}