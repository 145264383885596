/**
 * @copyright 2020 @ DigiNet
 * @author ANHHUY
 * @create 08/10/2020
 * @Example
 */

import Api from '../../../services/api';
import { put, cancel, take, takeLatest} from "redux-saga/effects";
import {types} from "../../W0X/W05F0006/W05F0006_actions";
import {delay} from "redux-saga";

export default function W05F0006Sagas () {
    return [
        watchGetSaleContracts(),
        watchGetMappingBySaleContract(),
        watchGetCboInventory(),
        watchGetCboOldMapping()
        
    ];
}

//get Sale Contracts ...
export function* getSaleContracts(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0006/get-sale-contracts', data.params);
        if(response && response.data) {
            yield put({
                type: types.W05F0006_GET_SALE_CONTRACTS_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch(e) {
        console.log('get Sale Contracts error');
    }
}

export function* watchGetSaleContracts() {
    while(true) {
        const watcher = yield takeLatest(types.W05F0006_GET_SALE_CONTRACTS, getSaleContracts);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }

}

//Get mapping by sale contract ...
export function* getMappingBySaleContract(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0006/get-mapping-by-sale-contract', data.params);
        if(response && response.data) {
            yield put({
                type: types.W05F0006_GET_MAPPING_BY_SALE_CONTRACT_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch(e) {
        console.log('get Sale Contracts error');
    }
}

export function* watchGetMappingBySaleContract() {
    while(true) {
        const watcher = yield takeLatest(types.W05F0006_GET_MAPPING_BY_SALE_CONTRACT, getMappingBySaleContract);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }

}

//Get Cb inventory
export function* getCboInventory(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-inventories', data.params);
        if(response && response.data) {
            yield put({
                type: types.W05F0006_GET_COMBO_INVENTORY_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch(e) {
        console.log('get Cb inventory error');
    }
}

export function* watchGetCboInventory() {
    while(true) {
        const watcher = yield takeLatest(types.W05F0006_GET_COMBO_INVENTORY, getCboInventory);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }

}

//Get Cb old mapping
export function* getCboOldMapping(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0006/list-old-mapping', data.params);
        if(response && response.data) {
            yield put({
                type: types.W05F0006_GET_COMBO_INVENTORY_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch(e) {
        console.log('get Cb inventory error');
    }
}

export function* watchGetCboOldMapping() {
    while(true) {
        const watcher = yield takeLatest(types.W05F0006_GET_COMBO_OLD_MAPPING, getCboOldMapping);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }

}
