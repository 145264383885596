import { types } from './W05F0001_actions';

const initialState = {
    getCboContractTypes: [],
    getCboTransTypes: [],
    getAmountOfContract: [],
    dataCboProject: []
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W05F0001_LOAD_CBO_CONTRACT_TYPE_SUCCESS:
            return { ...state, getCboContractTypes: action.data };
        case types.W05F1001_GET_CBO_TRANS_TYPE_SUCCESS:
            return { ...state, getCboTransTypes: action.data };
        case types.W05F0001_LOAD_AMOUNT_OF_CONTRACT_BY_STATUS_SUCCESS:
            return { ...state, getAmountOfContract: action.data };
        case types.W05F1001_GET_CBO_PROJECT_SUCCESS:
            return { ...state, dataCboProject: action.data };
        default:
            return state;
    }
}