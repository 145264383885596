/**
 * @copyright 2020 @ DigiNet
 * @author NHATLONG
 * @create 07/07/2020
 * @Example
 */
import Api from "../../../services/api";
export const types = {
    W05F0005_GET_STATUS: "W05F0005_GET_STATUS",
    W05F0005_GET_BANK: "W05F0005_GET_BANK",
    W05F0005_GET_LIST_INVENTORIES: "W05F0005_GET_LIST_INVENTORIES",
    W05F0005_GET_VOUCHER_NUM: "W05F0005_GET_VOUCHER_NUM",
    W05F0005_GET_DETAIL_SUPPLEMENT: "W05F0005_GET_DETAIL_SUPPLEMENT",
    W05F0005_GET_PACKING_METHOD: "W05F0005_GET_PACKING_METHOD",
    W05F0005_GET_PACKING_TYPE: "W05F0005_GET_PACKING_TYPE",
    W05F0005_GET_MARKING: "W05F0005_GET_MARKING",
    W05F0005_GET_CB_GENERAL: "W05F0005_GET_CB_GENERAL",
    W05F0005_GET_DECIMALS: "W05F0005_GET_DECIMALS",
    W05F0005_GET_DECIMALS_SUCCESS: "W05F0005_GET_DECIMALS_SUCCESS",
    W05F0005_GET_VESSEL: "W05F0005_GET_VESSEL",
    W05F0005_GET_PORT: "W05F0005_GET_PORT",
    W05F0005_ON_INSERT: "W05F0005_ON_INSERT",
    W05F0005_GET_FORM_EDIT: "W05F0005_GET_FORM_EDIT",
    W05F0005_GET_FORM_EDIT_SUCCESS: "W05F0005_GET_FORM_EDIT_SUCCESS",
    W05F0005_ON_UPDATE: "W05F0005_ON_UPDATE",
    W05F0005_GET_CB_FUMIGATION_METHOD: "W05F0005_GET_CB_FUMIGATION_METHOD",
    W05F0005_GET_GRID_FUMIGATION: "W05F0005_GET_GRID_FUMIGATION",
    W05F0005_SAVE_HISTORY: "W05F0005_SAVE_HISTORY",
    W05F0005_GET_HISTORY: "W05F0005_GET_HISTORY",
    W05F0005_GET_CBO_PAYMENT_METHOD: "W05F0005_GET_CBO_PAYMENT_METHOD",
    W05F0005_GET_CBO_PAYMENT_METHOD_SUCCESS: "W05F0005_GET_CBO_PAYMENT_METHOD_SUCCESS",
    W05F0005_GET_MASTER_SUPPLEMENT: "W05F0005_GET_MASTER_SUPPLEMENT",
    W05F0005_GET_MASTER_SUPPLEMENT_SUCCESS: "W05F0005_GET_MASTER_SUPPLEMENT_SUCCESS",
    W05F0005_FLEX_CAPTION: "W05F0005_FLEX_CAPTION",
    W05F0005_FLEX_CAPTION_SUCCESS: "W05F0005_FLEX_CAPTION_SUCCESS",
    W05F0005_GET_RECEIPT: 'W05F0005_GET_RECEIPT',
    W05F0005_GET_RECEIPT_SUCCESS: 'W05F0005_GET_RECEIPT_SUCCESS',
    W05F0005_GET_CB_OBJECTS: "W05F0005_GET_CB_OBJECTS",
    W05F0005_GET_CB_OBJECTS_SUCCESS: "W05F0005_GET_CB_OBJECTS_SUCCESS",
    W05F0005_SAVE_REQUEST_LOT: "W05F0005_SAVE_REQUEST_LOT",
    W05F0005_SAVE_REQUEST_LOT_SUCCESS: "W05F0005_SAVE_REQUEST_LOT_SUCCESS",
    W05F0005_GET_DELIVERY_QUANTITY: "W05F0005_GET_DELIVERY_QUANTITY",
    W05F0005_GET_DELIVERY_QUANTITY_SUCCESS: "W05F0005_GET_DELIVERY_QUANTITY_SUCCESS",
    W05F0005_SAVE_CONFIRM_DELIVERY_QUANTITY: "W05F0005_SAVE_CONFIRM_DELIVERY_QUANTITY",
    W05F0005_SAVE_CONFIRM_DELIVERY_QUANTITY_SUCCESS: "W05F0005_SAVE_CONFIRM_DELIVERY_QUANTITY_SUCCESS",
    W05F0005_SEND_REQUEST_DELETE_MAPPING: "W05F0005_SEND_REQUEST_DELETE_MAPPING",
    W05F0005_GET_CB_CURATOR: "W05F0005_GET_CB_CURATOR",
    W05F0005_GET_ADJUST_COLUMNS: "W05F0005_GET_ADJUST_COLUMNS",
    W05F0005_GET_ADJUST_COLUMNS_SUCCESS: "W05F0005_GET_ADJUST_COLUMNS_SUCCESS",
    W05F0005_GET_FIELD_COMPARE: "W05F0005_GET_FIELD_COMPARE",
    W05F0005_GET_FIELD_COMPARE_SUCCESS: "W05F0005_GET_FIELD_COMPARE_SUCCESS",
    W05F0005_GET_CB_LOCATION_NO: "W05F0005_GET_CB_LOCATION_NO",
    W05F0005_SAVE_LOCATION_NO: "W05F0005_SAVE_LOCATION_NO",
};

export function getCbStatus(params, cb) {
    return {
        type: types.W05F0005_GET_STATUS,
        params,
        cb
    }
}

export function getCbBank(params, cb) {
    return {
        type: types.W05F0005_GET_BANK,
        params,
        cb
    }
}
export function getListInventories(params, cb) {
    return {
        type: types.W05F0005_GET_LIST_INVENTORIES,
        params,
        cb
    }
}
export function getVoucherNum(params, cb) {
    return {
        type: types.W05F0005_GET_VOUCHER_NUM,
        params,
        cb
    }
}
export function getDetailSupplement(params, cb) {
    return {
        type: types.W05F0005_GET_DETAIL_SUPPLEMENT,
        params,
        cb
    }
}
export function getPackingMethod(params, cb) {
    return {
        type: types.W05F0005_GET_PACKING_METHOD,
        params,
        cb
    }
}
export function getPackingType(params, cb) {
    return {
        type: types.W05F0005_GET_PACKING_TYPE,
        params,
        cb
    }
}
export function getCbMarking(params, cb) {
    return {
        type: types.W05F0005_GET_MARKING,
        params,
        cb
    }
}
export function getCbGeneral(params, cb) {
    return {
        type: types.W05F0005_GET_CB_GENERAL,
        params,
        cb
    }
}
export function getDecimals(params, cb) {
    return {
        type: types.W05F0005_GET_DECIMALS,
        params,
        cb
    }
}
export function getCbVessel(params, cb) {
    return {
        type: types.W05F0005_GET_VESSEL,
        params,
        cb
    }
}
export function getCbPort(params, cb) {
    return {
        type: types.W05F0005_GET_PORT,
        params,
        cb
    }
}

export function onInsert(params, cb) {
    return {
        type: types.W05F0005_ON_INSERT,
        params,
        cb
    }
}

export function getFormEdit(params, cb) {
    return {
        type: types.W05F0005_GET_FORM_EDIT,
        params,
        cb
    }
}
export function onUpdate(params, cb) {
    return {
        type: types.W05F0005_ON_UPDATE,
        params,
        cb
    }
}

export function getCbFumigationMethod(params, cb) {
    return {
        type: types.W05F0005_GET_CB_FUMIGATION_METHOD,
        params,
        cb
    }
}

export function getGridFumigation(params, cb) {
    return {
        type: types.W05F0005_GET_GRID_FUMIGATION,
        params,
        cb
    }
}
export function saveHistory(params, cb) {
    return {
        type: types.W05F0005_SAVE_HISTORY,
        params,
        cb
    }
}


export function getHistory(params, cb) {
    return {
        type: types.W05F0005_GET_HISTORY,
        params,
        cb
    }
}

export function loadCboPaymentMethod(cb) {
    return {
        type: types.W05F0005_GET_CBO_PAYMENT_METHOD,
        cb
    }
}

export function getPaymentMethodDefault(params, cb) {
    return async () => {
        await Api.put('/w05f0004/get-payment-default', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    cb && cb(result, null);
                } else if (result.data) {
                    cb && cb(null, result.data);
                    return result.data;
                } else {
                    cb && cb(null, null);
                }
            });
    }
}

export function getMasterSupplement(params, cb) {
    return {
        type: types.W05F0005_GET_MASTER_SUPPLEMENT,
        params,
        cb
    }
}

export function getFlexCaption(params, cb) {
    return {
        type: types.W05F0005_FLEX_CAPTION,
        params,
        cb
    }
}

export function loadReceipt(params, cb) {
    return {
        type: types.W05F0005_GET_RECEIPT,
        params,
        cb
    }
}

export function getObjects(params, cb) {
    return {
        type: types.W05F0005_GET_CB_OBJECTS,
        params,
        cb
    }
}

export function saveRequestLot(params, cb) {
    return {
        type: types.W05F0005_SAVE_REQUEST_LOT,
        params,
        cb
    }
}

export function getDeliveryQuantity(params, cb) {
    return {
        type: types.W05F0005_GET_DELIVERY_QUANTITY,
        params,
        cb
    }
}

export function saveConfirmDeliveryQuantity(params, cb) {
    return {
        type: types.W05F0005_SAVE_CONFIRM_DELIVERY_QUANTITY,
        params,
        cb
    }
}

export function sendRequestDeleteMapping(params, cb) {
    return {
        type: types.W05F0005_SEND_REQUEST_DELETE_MAPPING,
        params,
        cb
    }
}

export function getCboCurator(params, cb) {
    return {
        type: types.W05F0005_GET_CB_CURATOR,
        params,
        cb
    }
}

export function getAdjustColumns(params, cb) {
    return {
        type: types.W05F0005_GET_ADJUST_COLUMNS,
        params,
        cb
    }
}

export function getFieldsCompare(params, cb) {
    return {
        type: types.W05F0005_GET_FIELD_COMPARE,
        params,
        cb
    }
}

export function getCbLocationNo(params, cb) {
    return {
        type: types.W05F0005_GET_CB_LOCATION_NO,
        params,
        cb
    }
}

export function saveLocationNo(params, cb) {
    return {
        type: types.W05F0005_SAVE_LOCATION_NO,
        params,
        cb
    }
}




