/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 07/07/2020
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import Api from '../../../services/api';
import { types } from "./W05F0003_actions";

export default function W05F0003Sagas() {
    return [
        watchGetDetailSupplement(),
        watchGetMasterSupplement(),
        watchLoadFormView(),
        watchGetDecimalQuantity(),
        watchGetCboBaseCurrency(),
        watchGetCboEmployee(),
        watchGetListDeployments(),
        watchGetCboFumigationMethod(),
        watchGetFumigationEdit(),
        watchSaveFumigation(),
        watchGetCboStatus(),
        watchSaveChangeStatus(),
        watchGetSaleContracts(),
        watchGetMappingBySaleContract(),
        watchGetReceipt(),
        watchSaveReceipt(),
        watchSaveCompleteReceipt(),
        watchGetPopupPayments(),
        watchSavePayments(),
        watchGetCboPaymentMenthod(),
        watchGetFlexCaption(),
        // watchGetComboFumigationCompany(),
    ];
}

//get detail supplement
export function* getDetailSupplement(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-detail-supplement', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0003_GET_DETAIL_SUPPLEMENT_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get detail supplement error');
    }
}

export function* watchGetDetailSupplement() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_GET_DETAIL_SUPPLEMENT, getDetailSupplement);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get master supplement
export function* getMasterSupplement(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-master-supplement', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0003_GET_MASTER_SUPPLEMENT_SUCCESS, data: response.data, params: data.params });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get master supplement error');
    }
}

export function* watchGetMasterSupplement() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_GET_MASTER_SUPPLEMENT, getMasterSupplement);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
//get load form
export function* loadFormView(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0003/load-form-view', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get load form error');
    }
}

export function* watchLoadFormView() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_LOAD_FORM_VIEW, loadFormView);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//save data
export function* saveDataContract(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/insert', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save error');
    }
}

export function* watchSaveDataContract() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_SAVE_DATA_CONTRACT, saveDataContract);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get decimal quantity
export function* getDecimalQuantity(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-decimal-quantity', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0003_GET_DECIMAL_QUANTITY_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get decimal quantity error');
    }
}

export function* watchGetDecimalQuantity() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_GET_DECIMAL_QUANTITY, getDecimalQuantity);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo base currency
export function* getCboBaseCurrency(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w06f0000/get-base-currency');
        if (response && response.data) {
            yield put({ type: types.W05F0003_GET_BASE_CURRENCY_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo base currency error');
    }
}

export function* watchGetCboBaseCurrency() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_GET_BASE_CURRENCY, getCboBaseCurrency);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo employee
export function* getCboEmployee(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0000/get-combo-employee', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0003_GET_CBO_EMPLOYEES_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo employee error');
    }
}

export function* watchGetCboEmployee() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_GET_CBO_EMPLOYEES, getCboEmployee);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


//get cbo employee
export function* getListDeployments(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/get-list-deployment', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get list deployments error');
    }
}

export function* watchGetListDeployments() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_GET_LIST_DEPLOYMENTS, getListDeployments);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get list Fumigation Edit ...
export function* getFumigationEdit(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0005/get-fumigation-edit', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get fumigation edit error')
    }
}

export function* watchGetFumigationEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_LOAD_FUMIGATION, getFumigationEdit);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

//get cbo FUMIGATION_METHOD ...
export function* getCboFumigationMethod(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0005/get-combo-fumigation-method', data.params);
        if (response && response.data) {
            yield put({
                type: types.W05F0003_LOAD_CBO_FUMIGATION_METHOD_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get Cbo fumigation method error')
    }
}

export function* watchGetCboFumigationMethod() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_LOAD_CBO_FUMIGATION_METHOD, getCboFumigationMethod);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get list OBJECTS ...
export function* getComboFumigationCompany(data) {
    const params = data.params;
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0005/get-combo-fumigation-company', params);
        if (response && response.data) {
            // yield put({ type: types.W05F0003_LOAD_CBO_FUMIGATION_COMPANY_SUCCESS, data: response.data, params });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo fumigation company error')
    }
}

export function* watchGetComboFumigationCompany() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_LOAD_CBO_FUMIGATION_COMPANY, getComboFumigationCompany);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get list Fumigation Edit ...
export function* saveFumigation(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/save-fumigation', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('save fumigation error')
    }
}

export function* watchSaveFumigation() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_SAVE_FUMIGATION, saveFumigation);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo status ...
export function* getCboStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0005/get-combo-status', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0003_LOAD_CBO_STATUS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo status error');
    }
}

export function* watchGetCboStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_LOAD_CBO_STATUS, getCboStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//save status changes ...
export function* saveChangeStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/save-change-status', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('save cbo Status changes error')
    }
}

export function* watchSaveChangeStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_SAVE_CHANGE_STATUS, saveChangeStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

//Get sale contracts
export function* getSaleContracts(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0006/get-sale-contracts', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0003_GET_SALE_CONTRACTS_SUCCESS, data: response.data, param: data.params });
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get sale contracts error')
    }
}

export function* watchGetSaleContracts() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_GET_SALE_CONTRACTS, getSaleContracts);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

//Get mapping by sale contract
export function* getMappingBySaleContract(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0006/get-mapping-by-sale-contract', data.params);
        if (response && response.data) {
            if (data.params.values) {
                const param = JSON.parse(data.params.values);
                yield put({ type: types.W05F0003_GET_MAPPING_BY_SALE_CONTRACTS_SUCCESS, data: response.data, param });
            }
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get mapping by sale contract error')
    }
}

export function* watchGetMappingBySaleContract() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_GET_MAPPING_BY_SALE_CONTRACTS, getMappingBySaleContract);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }

}

//Get license lot 
export function* getReceiptLot(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/get-receipts', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get receipt error')
    }
}

export function* watchGetReceipt() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_GET_RECEIPT, getReceiptLot);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Save license lot 
export function* saveReceipt(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/save-receipts', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('save finish receipt error')
    }
}

export function* watchSaveReceipt() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_SAVE_RECEIPT, saveReceipt);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Save finish receipt
export function* saveCompleteReceipt(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/update-complete', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('save receipt error')
    }
}

export function* watchSaveCompleteReceipt() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_SAVE_COMPLETE_RECEIPT, saveCompleteReceipt);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Get popup payment
export function* getPopupPayments(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/load-popup-payment', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get popup payment error')
    }
}

export function* watchGetPopupPayments() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_GET_POPUP_PAYMENT, getPopupPayments);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//Save payments
export function* savePayments(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0004/save-payments', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('save payments error')
    }
}

export function* watchSavePayments() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_SAVE_PAYMENT, savePayments);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo payment menthod
export function* getCboPaymentMenthod(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w05f0000/get-combo-payment-method');
        if (response && response.data) {
            yield put({ type: types.W05F0003_GET_CBO_PAYMENT_METHOD_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo payment method error');
    }

}

export function* watchGetCboPaymentMenthod() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_GET_CBO_PAYMENT_METHOD, getCboPaymentMenthod);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


//get flex caption
export function* getFlexCaption(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w05f0007/get-flex-caption', data.params);
        if (response && response.data) {
            yield put({ type: types.W05F0003_FLEX_CAPTION_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get flex caption error');
    }
}

export function* watchGetFlexCaption() {
    while (true) {
        const watcher = yield takeLatest(types.W05F0003_FLEX_CAPTION, getFlexCaption);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}